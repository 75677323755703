import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import store from '@/store'
import router from '@/router'
import api from './plugins/api'
import authorization from './plugins/auth'
import eds from './plugins/eds'
import notify from '@ananasbear/notify'
import validate from '@ananasbear/validation'
import i18n from '@/plugins/vue-i18n'
import Table from '@/components/modules/Table'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import VueSweetalert2 from 'vue-sweetalert2'
import DatePicker from '@/components/modules/DatePicker.vue'
import TimePicker from '@/components/modules/TimePicker.vue'
import DropdownLayout from '@/components/DropdownLayout.vue'

Vue.component('TableModule', Table)
Vue.component('DatePicker', DatePicker)
Vue.component('TimePicker', TimePicker)
Vue.component('DropdownLayout', DropdownLayout)

Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(VueSweetalert2)

// Styles
import 'sweetalert2/dist/sweetalert2.min.css'
import '@/assets/sass/menu.sass'
import '@/assets/sass/header.sass'
import '@/assets/sass/global.sass'
import '@/assets/sass/grid.sass'
import '@/assets/sass/fonts.sass'
import '@/assets/sass/sweetalert.sass'
import '@/assets/sass/overwrite.sass'
Vue.config.productionTip = false

Vue.prototype.$eds = eds
Vue.prototype.$notification = notify
Vue.prototype.$auth = authorization
Vue.prototype.$valid = validate
Vue.prototype.$labelName = i18n.locale === 'uk' ? 'name_ukr' : 'name_eng'
Vue.prototype.$api = api

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
