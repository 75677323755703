import api from '@/plugins/api'
import { directories as endpoints, singleDirectories } from '@/configs/endpoints'
import { successCodes } from '@/configs/constants'

export default {
  state: () => ({
    country: [],
    region: [],
    city: [],
    sex: [],
    statusDocument: [],
    eti: [],
    typesUser: [
      { id: 'admin', name_ukr: 'Адміністратор', name_eng: 'Admin' },
      { id: 'security', name_ukr: 'Адміністратор безпеки', name_eng: 'Security Administrator' },
      { id: 'marad_manager', name_ukr: 'Ведення ДРДМ', name_eng: 'Management of DRDM' },
      { id: 'head_marad_sqc', name_ukr: 'Голова ДККМ', name_eng: 'Head of the SQCM' },
      { id: 'marad_head_admin', name_ukr: 'Головний адміністратор', name_eng: 'Chief administrator' },
      { id: 'diia', name_ukr: 'Моряк з Дії', name_eng: 'Sailor from Diia' },
      { id: 'shipping_administration', name_ukr: 'Оператор Адміністрації судноплавства', name_eng: 'Operator of the Shipping Administration' },
      { id: 'official_morrichservice', name_ukr: 'Оператор ДП "Моррічсервіс"', name_eng: 'Operator SE Morrichservice' },
      { id: 'secretary_eti', name_ukr: 'Представник ЗПМ', name_eng: 'A representative of the STI' },
      { id: 'secretary_appeal_commission', name_ukr: 'Секретар апеляційної комісії', name_eng: 'Secretary of the appeal commission' },
      { id: 'supervisor', name_ukr: 'Супервайзер', name_eng: 'Supervisor' },
      { id: 'marad_auth_person', name_ukr: 'Уповноважена особа', name_eng: 'Authorized Person' },
      { id: 'member_appeal', name_ukr: 'Член апеляційної комісії', name_eng: 'Member of the appeal commission' },
      { id: 'secretary_sqc', name_ukr: 'Член ДККМ', name_eng: 'Member of the SQCM' },
      { id: 'member_marad_sqc', name_ukr: 'Член ДККМ (заяви на іспит)', name_eng: 'Member of the SQCM (applications for the exam)' }
    ],
    listTypeActive: [
      { id: 2, name_ukr: 'Активні', name_eng: 'Active', value: 'true' },
      { id: 3, name_ukr: 'Деактивовані', name_eng: 'Deactivated', value: 'false' },
      { id: 1, name_ukr: 'Усі', name_eng: 'All', value: null }
    ],
    diiaDocumentTypes: [
      { id: 'endorsement', name_ukr: 'Підтвердження професійного диплому' },
      { id: 'endorsement_of_competency', name_ukr: 'Підтвердження сертифіката компетентності' },
      { id: 'diploma', name_ukr: 'Професійний диплом' },
      { id: 'certificate', name_ukr: 'Свідоцтво фахівця' },
      { id: 'ranker_certificate', name_ukr: 'Свідоцтво фахівця (рядовий склад)' },
      { id: 'certificate_of_competency', name_ukr: 'Сертифікат компетентності' }
    ],
    diiaRanks: [],
    diiaBranchOffices: [],
    diiaEducationLevels: [],
    diiaEducationInstitutions: [],
    diiaReasons: [],
    diiaPositions: [],
    diiaTypeCertificates: [],
    diiaFunctions: [],
    diiaLevels: [],
    diiaLimitations: [],
    diiaPositionFunctionsAndLevels: [],
    diiaFunctionLimitations: [],
    diiaPositionLimitations: [],
    diiaEtiCourses: [],
    diiaSti: [],
    diiaMedicalInstitutions: [],
    diiaDecisions: [
      { id: 2, name_ukr: 'Відмовити у наданні кваліфікаційного документа' },
      { id: 3, name_ukr: 'Відмовлено' },
      { id: 4, name_ukr: 'Відправлено до ДККМ на повторний перегляд' },
      { id: 1, name_ukr: 'Надати кваліфікаційний документ' }
    ],
    diiaDirectories: {},
    directoryDetails: {},
    typeDirectoryList: [
      {
        id: 'educational_type_documents',
        name_ukr: 'Документи про освіту',
        setMethod: 'setDiiaEducationalTypeDocuments',
        updateMethod: 'updateDiiaEducationalTypeDocuments',
        deleteMethod: 'removeDiiaEducationalTypeDocuments',
        routeType: 'educational-type-documents',
        content_type_name: 'diiaeducationaltypedocument'
      },
      {
        id: 'sti',
        name_ukr: 'Заклади підготовки моряків',
        updateMethod: 'updateDiiaSti',
        setMethod: 'setDiiaSti',
        deleteMethod: 'removeDiiaSti',
        routeType: 'sti',
        directoryValue: 'diiaSti',
        content_type_name: 'diiasti'
      },
      {
        id: 'ranks',
        name_ukr: 'Звання',
        updateMethod: 'updateDiiaRanks',
        routeType: 'ranks',
        directoryValue: 'diiaRanks',
        content_type_name: 'diiarank'
      },
      {
        id: 'type_certificates',
        name_ukr: 'Кваліфікації свідоцтв',
        updateMethod: 'updateDiiaTypeCertificates',
        routeType: 'type-certificates',
        directoryValue: 'diiaTypeCertificates',
        content_type_name: 'diiatypecertificate'
      },
      // {
      //   id: 'medical_institutions',
      //   name_ukr: 'Медичні заклади',
      //   updateMethod: 'updateDiiaMedicalInstitutions',
      //   setMethod: 'setDiiaMedicalInstitutions',
      //   deleteMethod: 'removeDiiaMedicalInstitutions',
      //   routeType: 'medical-institutions',
      //   directoryValue: 'diiaMedicalInstitutions',
      //   content_type_name: 'diiamedicalinstitution'
      // },
      {
        id: 'educational_institutions',
        name_ukr: 'Навчальні заклади',
        updateMethod: 'updateDiiaEducationalInstitutions',
        setMethod: 'setDiiaEducationalInstitutions',
        deleteMethod: 'removeDiiaEducationalInstitutions',
        routeType: 'education-institutions',
        directoryValue: 'diiaEducationInstitutions',
        content_type_name: 'diiaeducationalinstitution'
      },
      {
        id: 'eti_courses',
        name_ukr: 'Напрями підготовки',
        updateMethod: 'updateDiiaEtiCourses',
        setMethod: 'setDiiaEtiCourses',
        deleteMethod: 'removeDiiaEtiCourses',
        routeType: 'eti-courses',
        directoryValue: 'diiaEtiCourses',
        content_type_name: 'diiacourse'
      },
      {
        id: 'limitations_for_position',
        name_ukr: 'Обмеження за посадами',
        // updateMethod: 'updateDiiaPositionLimitations',
        routeType: 'limitations-for-position',
        directoryValue: 'diiaPositionLimitations',
        content_type_name: 'diialimitationforposition'
      },
      {
        id: 'limitations_for_function',
        name_ukr: 'Обмеження за функціями',
        updateMethod: 'updateDiiaFunctionLimitations',
        routeType: 'limitations-for-function',
        directoryValue: 'diiaFunctionLimitations',
        content_type_name: 'diialimitationforfunction'
      },
      {
        id: 'type_document_for_statement',
        name_ukr: 'Обовʼязкові документи',
        updateMethod: 'updateDiiaStatementDocuments',
        routeType: 'statement-required-documents',
        content_type_name: 'diiatypedocumentsforstatement'
      },
      {
        id: 'positions',
        name_ukr: 'Посади',
        updateMethod: 'updateDiiaPositions',
        routeType: 'positions',
        directoryValue: 'diiaPositions',
        content_type_name: 'diiaposition'
      },
      {
        id: 'reasons',
        name_ukr: 'Причини відхилення',
        updateMethod: 'updateDiiaReasons',
        setMethod: 'setDiiaReasons',
        deleteMethod: 'removeDiiaReasons',
        routeType: 'reasons',
        directoryValue: 'diiaReasons',
        content_type_name: 'diiareason'
      },
      {
        id: 'educational_specialities',
        name_ukr: 'Спеціальності',
        setMethod: 'setDiiaEducationalSpecialities',
        updateMethod: 'updateDiiaEducationalSpecialities',
        deleteMethod: 'removeDiiaEducationalSpecialities',
        routeType: 'educational-specialities',
        directoryValue: 'diiaEducationalSpecialities',
        content_type_name: 'diiaeducationspeciality'
      },
      {
        id: 'branch_offices',
        name_ukr: 'Філії',
        updateMethod: 'updateDiiaBranchOffices',
        setMethod: 'setDiiaBranchOffices',
        deleteMethod: 'removeDiiaBranchOffices',
        routeType: 'branch-offices',
        directoryValue: 'diiaBranchOffices',
        content_type_name: 'diiabranchoffice'
      }
    ],
    typeStatementsList: [
      {
        name_ukr: 'Отримання кваліфікаційного документа',
        content_type_name: 'diiastatementworkdocument'
      },
      {
        name_ukr: 'Оскарження рішень Адміністрації судноплавства',
        content_type_name: 'diiastatementworkdocumentappeal'
      },
      {
        name_ukr: 'Проведення перевірки професійної компетентності',
        content_type_name: 'diiastatementexam'
      },
      {
        name_ukr: 'Виправлення відомостей у ДРДМ',
        content_type_name: 'diiastatementchangerequest'
      }
    ],
    typeDocumentsList: [
      {
        name_ukr: 'Кваліфікаційні документи',
        content_type_name: 'diiaworkdocument'
      },
      {
        name_ukr: 'Свідоцтва ЗПМ',
        content_type_name: 'diiacertificateeti'
      }
    ],
    diiaStatementTypes: [
      { id: 5, name_ukr: 'Підтвердження за відсутності стажу' },
      { id: 2, name_ukr: 'Підтвердження по стажу' },
      { id: 3, name_ukr: 'Підтвердження через іспит' },
      { id: 4, name_ukr: 'Підтвердження через курси підвищення кваліфікації' },
      { id: 1, name_ukr: 'Присвоєння' }
    ],
    branchOfficeAppointments: [
      { id: 1, name_ukr: 'Для здачі іспиту' },
      { id: 2, name_ukr: 'Для отримання оригіналу кваліфікаційного документа' }
    ],
    presenceTypes: [
      { id: 2, name_ukr: 'Моряк буде присутній дистанційно' },
      { id: 3, name_ukr: 'Моряк буде присутній фізично' },
      { id: 1, name_ukr: 'Розгляд без присутності моряка' }
    ],
    userStatuses: [
      { id: 2, name_ukr: 'Відсутній' },
      { id: 1, name_ukr: 'Присутній' }
    ],
    diiaVesselTypes: [],
    diiaEducationalSpecialities: [],
    diiaEducationalTypeDocuments: [],
    typeAuditList: [
      { id: 'authorizations', name_ukr: 'Авторизація', routeType: 'authorizations' },
      { id: 'directories', name_ukr: 'Довідники', routeType: 'directories' },
      { id: 'documents', name_ukr: 'Документи', routeType: 'documents' },
      { id: 'requests', name_ukr: 'Заяви', routeType: 'requests' },
      { id: 'users', name_ukr: 'Користувачі', routeType: 'users' }
    ],
    operationsStates: [
      { id: 'deleted', name_ukr: 'Видалення' },
      { id: 'logout', name_ukr: 'Вихід з системи' },
      { id: 'login', name_ukr: 'Вхід в систему' },
      { id: 'updated', name_ukr: 'Редагування' },
      { id: 'created', name_ukr: 'Створення' }
    ],
    shortAnswersList: [
      { id: false, name_ukr: 'Ні', value: 'false' },
      { id: true, name_ukr: 'Так', value: 'true' }
    ],
    experienceTypes: [
      { id: 3, name_ukr: 'Інший документ про стаж' },
      { id: 2, name_ukr: 'Книга реєстрації підготовки' },
      { id: 1, name_ukr: 'Послужна книжка моряка' }
    ],
    inspectionPurposes: [
      { id: 2, name_ukr: 'Підтвердження збереження професійної компетентності' },
      { id: 1, name_ukr: 'Присвоєння звання' }
    ],
    appealStatementTypes: [
      {
        id: 'work_document_appeal',
        routeType: 'work-document-decision',
        name_ukr: 'Оскарження рішень видачі кваліфікаційного документа',
        access: 'statement-work-document-decision-appeal'
      },
      {
        id: 'exam_appeal',
        routeType: 'professional-competence',
        name_ukr: 'Оскарження рішень за результатами іспиту',
        access: 'statement-professional-competence-appeal'
      }
    ],
    identityDocumentTypes: [
      { id: 1, name_ukr: 'Інший документ громадянини України' },
      { id: 2, name_ukr: 'Посвідка біженця громадянина іноземної держави' },
      { id: 3, name_ukr: 'Посвідка на постійне проживання' },
      { id: 4, name_ukr: 'Посвідка на тимчасове проживання' },
      { id: 5, name_ukr: 'Посвідчення особи моряка' }
    ],
    memberDecisionsList: [
      { id: false, name_ukr: 'Очікує рішення', value: 'false' },
      { id: true, name_ukr: 'Рішення винесено', value: 'true' }
    ],
    submittingDocumentStatuses: [
      { id: 2, name_ukr: 'Внесено' },
      { id: 1, name_ukr: 'В обробці' },
      { id: 3, name_ukr: 'Дані внесено частково' },
      { id: 4, name_ukr: 'Дані не внесено' }
    ],
    isLoading: false,
    isLoadedDirectories: false
  }),
  mutations: {
    SET_DATA_D (state, data) {
      Object.entries(data).forEach(([key, value]) => state[key] = value)
    },
    UPDATE_DATA_D (state, data) {
      Object.entries(data).forEach(([key, value]) => {
        const index = state[key].findIndex(item => item.id === value.id)
        if (index !== -1) state[key].splice(index, 1, value)
      })
    },
    UPDATE_DATA_RESULTS_D (state, data) {
      Object.entries(data).forEach(([key, value]) => {
        const index = state[key].results.findIndex(item => item.id === value.id)
        if (index !== -1) state[key].results.splice(index, 1, value)
      })
    },
    DELETE_DATA_D (state, data) {
      Object.entries(data).forEach(([key, id]) => {
        const index = state[key].findIndex(item => item.id === id)
        if (index !== -1) state[key].splice(index, 1)
      })
    },
    DELETE_DATA_RESULTS_D (state, data) {
      Object.entries(data).forEach(([key, id]) => {
        const index = state[key].results.findIndex(item => item.id === id)
        if (index !== -1) state[key].results.splice(index, 1)
      })
    }
  },
  actions: {
    async getDirectories ({ commit }) {
      for (const endpoint of endpoints) {
        api.get(endpoint.endpoint).then(response => {
          if (successCodes.includes(response?.code)) commit('SET_DATA_D', { [endpoint.state]: response.data })
        })
        commit('SET_DATA_D', { isLoadedDirectories: true })
      }
    },
    async getDiiaDirectories({ commit }, params) {
      if (!params.includes('type_directory')) {
        commit('SET_DATA_D', { diiaDirectories: {} })
        return false
      }
      commit('SET_DATA_D', { isLoading: true })
      const response =  await api.get(singleDirectories.diiaDirectories() + params)
      if (successCodes.includes(response?.code)) commit('SET_DATA_D', { diiaDirectories: response.data })
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    async getDiiaDirectory({ commit }, { id, params }) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.get(singleDirectories.diiaDirectories(id) + params)
      if (successCodes.includes(response?.code)) commit('SET_DATA_D', { directoryDetails: response.data })
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    // BRANCH OFFICES
    async setDiiaBranchOffices ({ commit }, body) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.post(singleDirectories.diiaBranchOffices(), { body })
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    async updateDiiaBranchOffices ({ commit }, { id, body }) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.patch(singleDirectories.diiaBranchOffices(id), { body })
      if (successCodes.includes(response?.code)) {
        commit('UPDATE_DATA_RESULTS_D', { diiaDirectories: response.data })
        commit('SET_DATA_D', { directoryDetails: response.data })
      }
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    async removeDiiaBranchOffices ({ commit }, id) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.delete(singleDirectories.diiaBranchOffices(id))
      if (successCodes.includes(response?.code)) {
        commit('DELETE_DATA_RESULTS_D', { diiaDirectories: id })
        commit('DELETE_DATA_D', { diiaBranchOffices: id })
      }
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    // EDUCATIONAL INSTITUTIONS
    async setDiiaEducationalInstitutions ({ commit }, body) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.post(singleDirectories.diiaEducationalInstitutions(), { body })
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    async updateDiiaEducationalInstitutions ({ commit }, { id, body }) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.patch(singleDirectories.diiaEducationalInstitutions(id), { body })
      if (successCodes.includes(response?.code)) {
        commit('UPDATE_DATA_RESULTS_D', { diiaDirectories: response.data })
        commit('SET_DATA_D', { directoryDetails: response.data })
      }
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    async removeDiiaEducationalInstitutions ({ commit }, id) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.delete(singleDirectories.diiaEducationalInstitutions(id))
      if (successCodes.includes(response?.code)) {
        commit('DELETE_DATA_RESULTS_D', { diiaDirectories: id })
        commit('DELETE_DATA_D', { diiaEducationInstitutions: id })
      }
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    // REJECT REASONS
    async setDiiaReasons ({ commit }, body) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.post(singleDirectories.diiaReasons(), { body })
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    async updateDiiaReasons ({ commit }, { id, body }) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.patch(singleDirectories.diiaReasons(id), { body })
      if (successCodes.includes(response?.code)) {
        commit('UPDATE_DATA_RESULTS_D', { diiaDirectories: response.data })
        commit('SET_DATA_D', { directoryDetails: response.data })
      }
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    async removeDiiaReasons ({ commit }, id) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.delete(singleDirectories.diiaReasons(id))
      if (successCodes.includes(response?.code)) {
        commit('DELETE_DATA_RESULTS_D', { diiaDirectories: id })
        commit('DELETE_DATA_D', { diiaReasons: id })
      }
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    // EDUCATION SPECIALITIES
    async setDiiaEducationalSpecialities ({ commit }, body) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.post(singleDirectories.diiaEducationalSpecialities(), { body })
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    async updateDiiaEducationalSpecialities ({ commit }, { id, body }) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.patch(singleDirectories.diiaEducationalSpecialities(id), { body })
      if (successCodes.includes(response?.code)) {
        commit('UPDATE_DATA_RESULTS_D', { diiaDirectories: response.data })
        commit('SET_DATA_D', { directoryDetails: response.data })
      }
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    async removeDiiaEducationalSpecialities ({ commit }, id) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.delete(singleDirectories.diiaEducationalSpecialities(id))
      if (successCodes.includes(response?.code)) {
        commit('DELETE_DATA_RESULTS_D', { diiaDirectories: id })
        commit('DELETE_DATA_D', { diiaEducationalSpecialities: id })
      }
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    // RANKS
    async updateDiiaRanks ({ commit }, { id, body }) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.patch(singleDirectories.diiaRanks(id), { body })
      if (successCodes.includes(response?.code)) {
        commit('UPDATE_DATA_RESULTS_D', { diiaDirectories: response.data })
        commit('SET_DATA_D', { directoryDetails: response.data })
      }
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    // POSITIONS
    async updateDiiaPositions ({ commit }, { id, body }) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.patch(singleDirectories.diiaPositions(id), { body })
      if (successCodes.includes(response?.code)) {
        commit('UPDATE_DATA_RESULTS_D', { diiaDirectories: response.data })
        commit('SET_DATA_D', { directoryDetails: response.data })
      }
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    // CERTIFICATE TYPES
    async updateDiiaTypeCertificates ({ commit }, { id, body }) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.patch(singleDirectories.diiaTypeCertificates(id), { body })
      if (successCodes.includes(response?.code)) {
        commit('UPDATE_DATA_RESULTS_D', { diiaDirectories: response.data })
        commit('SET_DATA_D', { directoryDetails: response.data })
      }
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    // FUNCTION LIMITATIONS
    async updateDiiaFunctionLimitations ({ commit, state }, { id, body }) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.patch(singleDirectories.diiaLimitations(id), { body })
      if (successCodes.includes(response?.code)) {
        commit('UPDATE_DATA_RESULTS_D', { diiaDirectories: { ...state.directoryDetails, limitation: response.data } })
        commit('SET_DATA_D', { directoryDetails: { ...state.directoryDetails, limitation: response.data } })
      }
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    // POSITION LIMITATIONS
    async updateDiiaPositionLimitations ({ commit }, { id, body }) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.patch(singleDirectories.diiaPositionLimitations(id), { body })
      if (successCodes.includes(response?.code)) {
        commit('UPDATE_DATA_RESULTS_D', { diiaDirectories: response.data })
        commit('SET_DATA_D', { directoryDetails: response.data })
      }
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    // STATEMENT DOCUMENTS
    async updateDiiaStatementDocuments ({ commit }, { id, body }) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.patch(singleDirectories.diiaStatementDocuments(id), { body })
      if (successCodes.includes(response?.code)) {
        commit('UPDATE_DATA_RESULTS_D', { diiaDirectories: response.data })
        commit('SET_DATA_D', { directoryDetails: response.data })
      }
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    // EDUCATION DOCUMENT TYPES
    async setDiiaEducationalTypeDocuments ({ commit }, body) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.post(singleDirectories.diiaEducationalTypeDocuments(), { body })
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    async updateDiiaEducationalTypeDocuments ({ commit }, { id, body }) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.patch(singleDirectories.diiaEducationalTypeDocuments(id), { body })
      if (successCodes.includes(response?.code)) {
        commit('UPDATE_DATA_RESULTS_D', { diiaDirectories: response.data })
        commit('SET_DATA_D', { directoryDetails: response.data })
      }
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    async removeDiiaEducationalTypeDocuments ({ commit }, id) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.delete(singleDirectories.diiaEducationalTypeDocuments(id))
      if (successCodes.includes(response?.code)) commit('DELETE_DATA_RESULTS_D', { diiaDirectories: id })
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    // EDUCATIONAL INSTITUTIONS
    async setDiiaEtiCourses ({ commit }, body) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.post(singleDirectories.diiaEtiCourses(), { body })
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    async updateDiiaEtiCourses ({ commit }, { id, body }) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.patch(singleDirectories.diiaEtiCourses(id), { body })
      if (successCodes.includes(response?.code)) {
        commit('UPDATE_DATA_RESULTS_D', { diiaDirectories: response.data })
        commit('SET_DATA_D', { directoryDetails: response.data })
      }
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    async removeDiiaEtiCourses ({ commit }, id) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.delete(singleDirectories.diiaEtiCourses(id))
      if (successCodes.includes(response?.code)) {
        commit('DELETE_DATA_RESULTS_D', { diiaDirectories: id })
        commit('DELETE_DATA_D', { diiaEtiCourses: id })
      }
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    // STI
    async setDiiaSti ({ commit }, body) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.post(singleDirectories.diiaSti(), { body })
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    async updateDiiaSti ({ commit }, { id, body }) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.patch(singleDirectories.diiaSti(id), { body })
      if (successCodes.includes(response?.code)) {
        commit('UPDATE_DATA_RESULTS_D', { diiaDirectories: response.data })
        commit('SET_DATA_D', { directoryDetails: response.data })
      }
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    async removeDiiaSti ({ commit }, id) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.delete(singleDirectories.diiaSti(id))
      if (successCodes.includes(response?.code)) {
        commit('DELETE_DATA_RESULTS_D', { diiaDirectories: id })
        commit('DELETE_DATA_D', { diiaSti: id })
      }
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    // MEDICAL INSTITUTIONS
    getDiiaMedicalInstitutions (context, params) {
      return api.get(singleDirectories.diiaMedicalInstitutions() + params)
    },
    async setDiiaMedicalInstitutions ({ commit }, body) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.post(singleDirectories.diiaMedicalInstitutions(), { body })
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    async updateDiiaMedicalInstitutions ({ commit }, { id, body }) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.patch(singleDirectories.diiaMedicalInstitutions(id), { body })
      if (successCodes.includes(response?.code)) {
        commit('UPDATE_DATA_RESULTS_D', { diiaDirectories: response.data })
        commit('SET_DATA_D', { directoryDetails: response.data })
      }
      commit('SET_DATA_D', { isLoading: false })
      return response
    },
    async removeDiiaMedicalInstitutions ({ commit }, id) {
      commit('SET_DATA_D', { isLoading: true })
      const response = await api.delete(singleDirectories.diiaMedicalInstitutions(id))
      if (successCodes.includes(response?.code)) {
        commit('DELETE_DATA_RESULTS_D', { diiaDirectories: id })
        commit('DELETE_DATA_D', { diiaMedicalInstitutions: id })
      }
      commit('SET_DATA_D', { isLoading: false })
      return response
    }
  },
  getters: {
    directoryById: state => (directory, id) => state[directory].find(item => item.id === id),
    diiaPositionsById: (state, getters) => positionsList => { // Get joined ranks list of id's by labelName
      if (state.diiaPositions.length && positionsList?.length) {
        return positionsList.map(item => getters.directoryById('diiaPositions', item)?.name_ukr).join(', ')
      } else return '-'
    },
    directionByKey: state => (directory, key, value) => state[directory].find(el => el[key] === value )
  }
}
