<template lang="pug">
div
  div(@click="isExpanded = !isExpanded").d-flex.align-center.justify-space-between.cursor-pointer
    div.fs-14.font-u--m {{ title }}
    v-icon(color="black" :class="{ 'rotate': isExpanded }") mdi-chevron-down
  transition(name="dropdown")
    div(v-if="isExpanded").dropdown-content
      slot
</template>

<script>
export default {
  name: 'DropdownLayout',
  props: {
    title: { type: String, default: '' },
    expanded: { type: Boolean, default: false }
  },
  data () {
    return {
      isExpanded: false
    }
  },
  beforeMount () {
    this.isExpanded = this.expanded
  }
}
</script>

<style lang="sass" scoped>
.dropdown-enter-active, .dropdown-leave-active
  transition: all 0.3s ease

.dropdown-enter, .dropdown-leave-to
  max-height: 0
  opacity: 0
  overflow: hidden

.dropdown-enter-to, .dropdown-leave
  max-height: 1000px
  opacity: 1
  overflow: hidden

.rotate
  transition: transform 0.3s ease
  transform: rotate(180deg)
</style>
