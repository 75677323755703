const successStatuses = [2, 9, 12, 19, 24, 29, 39, 41, 42, 43, 47, 55, 58, 61, 64, 67, 70, 75, 78, 81, 85,
	86, 87, 90, 92, 95, 100, 125, 126, 141]
const processStatuses = [5, 14, 21, 25, 34, 48, 52, 60, 63, 66, 69, 72, 77, 80, 82, 83, 88, 89, 94, 99, 112, 121, 156]
const warningStatuses = [1, 7, 16, 17, 20, 31, 32, 35, 44, 45, 46, 50, 51, 53, 74]
const errorStatuses = [6, 10, 13, 18, 23, 30, 33, 36, 37, 49, 56, 62, 65, 68, 71, 73, 76, 79, 84, 91, 93, 96, 98, 153]
const secondaryStatuses = [7, 8, 54, 57, 97, 124]

export const getSubmittingDocumentStatus = id => {
  switch (id) {
    case 1: return { color: 'primary lighten-1', textColor: 'primary--text'}
    case 2: return { color: 'success lighten-1', textColor: 'success--text'}
    case 3: return { color: 'warning lighten-1', textColor: 'warning--text'}
    case 4: return { color: 'error lighten-1', textColor: 'error--text'}
    default: return { color: 'primary lighten-1', textColor: 'primary--text' }
  }
}

export default (id) => {
	switch (true) {
		case successStatuses.includes(id): return { color: 'success lighten-1', textColor: 'success--text'}
		case processStatuses.includes(id): return { color: 'primary lighten-1', textColor: 'primary--text' }
		case warningStatuses.includes(id): return { color: 'warning lighten-1', textColor: 'warning--text' }
		case errorStatuses.includes(id): return { color: 'error lighten-1', textColor: 'error--text' }
		case secondaryStatuses.includes(id): return { color: 'secondary lighten-1', textColor: 'secondary--text' }
		default: return { color: 'primary lighten-1', textColor: 'primary--text' }
	}
}
