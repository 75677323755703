import api, { checkFiles } from '@/plugins/api'
import { statements, client } from '@/configs/endpoints'
import { successCodes } from '@/configs/constants'
import { groupArrayByObjectKey } from '@/configs'

let requestController = null

export default {
  state: () => ({
    isLoading: false,
    workDocument: {},
    workDocumentOpen: {},
    workDocumentDecision: {},
    assignedUsers: [],
    allAssignedUsers: [],
    professionalCompetence: {},
    professionalCompetenceOpen: {},
    professionalCompetenceExamResults: {},
    informationCorrection: {},
    informationCorrectionOpen: {},
    appeal: {},
    appealOpen: {}
  }),
  mutations: {
    SET_DATA_STATEMENT(state, data) {
      Object.entries(data).forEach(([key, value]) => (state[key] = value))
    }
  },
  actions: {
    updateRequestController () {
      // Used to stop execution of a previous request
      if (requestController) requestController.abort()
      requestController = new AbortController()
    },

    // WORK DOCUMENTS
    async getWorkDocumentStatements({ commit, dispatch }, params) {
      dispatch('updateRequestController')
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response =  await api.get(
        statements.workDocument() + params,
        { signal: requestController.signal })
      if (successCodes.includes(response?.code)) commit('SET_DATA_STATEMENT', { workDocument: response.data })
      if (response?.data.name !== 'AbortError') commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async setWorkDocumentStatement({ commit }, body) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response =  await api.post(statements.workDocument(), { body })
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async getWorkDocumentStatement({ commit }, documentID) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response = await api.get(statements.workDocument(documentID))
      if (successCodes.includes(response?.code)) {
        // Group the decisions of the Commission into rounds
        response.data.collegial_decisions = Object.groupBy
          ? Object.groupBy(response.data.collegial_decisions, ({ round }) => round)
          : groupArrayByObjectKey(response.data.collegial_decisions, 'round')
        // Get work document statement comments
        const commentResponse = await api.get(statements.workDocumentComment(documentID))
        if (successCodes.includes(commentResponse?.code)) response.data.comments = commentResponse.data
        response.data.isFilesLoading = true
        commit('SET_DATA_STATEMENT', { workDocumentOpen: response.data })
        // Load statement files below
        if (response.data.files?.length) response.data.files = await checkFiles(response.data.files)
        if (response.data.decision_files?.length) response.data.decision_files = await checkFiles(response.data.decision_files)
        response.data.diia_work_documents.map(async item => {
          if (item.files?.length) item.files = await checkFiles(item.files)
          return item
        })
        response.data.education_documents.map(async item => {
          if (item.files?.length) item.files = await checkFiles(item.files)
          return item
        })
        response.data.experience_documents.map(async item => {
          if (item.files?.length) item.files = await checkFiles(item.files)
          return item
        })
        response.data.experience_documents.forEach((item, index) => {
          if (item.records?.length) response.data.experience_documents[index].records.map(async record => {
            record.files = await checkFiles(record.files)
            return record
          })
        })
        response.data.medical_certificates.map(async item => {
          if (item.files?.length) item.files = await checkFiles(item.files)
          return item
        })
        response.data.protocols.map(async item => {
          if (item.files?.length) item.files = await checkFiles(item.files)
          return item
        })
        response.data.qualification_documents.map(async item => {
          if (item.files?.length) item.files = await checkFiles(item.files)
          return item
        })
        response.data.identity_documents?.map(async item => {
          if (item.files?.length) item.files = await checkFiles(item.files)
          return item
        })
        response.data.isFilesLoading = false
        commit('SET_DATA_STATEMENT', { workDocumentOpen: response.data, isLoading: false })
      }
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async updateWorkDocumentStatement({ commit }, data) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response = await api.patch(statements.workDocument(data.id),{ body: data.body })
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async removeWorkDocumentStatement({ commit }, id) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response = await api.delete(statements.workDocument(id), { responseType: 'text' })
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async uploadFilesWorkDocumentStatement({ commit }, { id, files, file_type }) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const body = new FormData()
      files.forEach(file => body.append('files', file))
      if (file_type) body.append('file_type', file_type)
      const response = await api.post(statements.workDocumentUploadFiles(id), { body })
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async assignLimitationsWorkDocumentStatement({ commit }, data) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response = await api.post(statements.assignLimitationsWorkDocument(data.id),{ body: data.body })
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    generateWorkDocumentStatement(context, id) {
      return api.get(statements.generateWorkDocumentStatement(id))
    },
    async generateDecisionWorkDocumentStatement({ commit }, { id, body }) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response = await api.post(statements.generateDecisionWorkDocument(id), { body })
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    generateCombinedFileWorkDocumentStatement(context, id) {
      return api.get(statements.combinedFileWorkDocumentStatement(id))
    },
    updateWorkDocumentStatementDecisionBody ({ state }, body) {
      if (state.workDocumentDecision.diia_decision) {
        body.append('diia_decision', state.workDocumentDecision.diia_decision)
        if (state.workDocumentDecision.diia_decision === 1 && state.workDocumentDecision.diia_list_position) {
          body.append('diia_list_position', JSON.stringify(state.workDocumentDecision.diia_list_position))
        }
        else if (state.workDocumentDecision.diia_decision === 2 && state.workDocumentDecision.additional_data) {
          body.append('additional_data', JSON.stringify(state.workDocumentDecision.additional_data))
        }
      }
      return body
    },
    async setSignFileWorkDocumentStatementDecision ({ dispatch }, body) {
      const modifiedBody = await dispatch('updateWorkDocumentStatementDecisionBody', body)
      return api.post(statements.signDecisionWorkDocument, { body: modifiedBody })
    },
    async setSignTokenWorkDocumentStatementDecision ({ dispatch }, body) {
      const modifiedBody = await dispatch('updateWorkDocumentStatementDecisionBody', body)
      return api.post(statements.signTokenDecisionWorkDocument, { body: modifiedBody })
    },
    async setSignIdGovUaWorkDocumentStatementDecision ({ dispatch }, body) {
      const modifiedBody = await dispatch('updateWorkDocumentStatementDecisionBody', body)
      return api.post(statements.signIdGovUaDecisionWorkDocument, { body: modifiedBody })
    },
    async generateWorkDocument({ commit }, { id, body }) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response = await api.post(statements.generateWorkDocument(id), { body })
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    setSignFileWorkDocument (context, body) {
      return api.post(statements.signWorkDocument, { body })
    },
    setSignTokenWorkDocument (context, body) {
      return api.post(statements.signTokenWorkDocument, { body })
    },
    setSignIdGovUaWorkDocument (context, body) {
      return api.post(statements.signIdGovUaWorkDocument, { body })
    },
    async setWorkDocumentStatementExtraDocument ({ commit, dispatch }, { id, type, body, files, content_type }) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response = await api.post(statements.workDocumentStatementExtraDocument(id, type), { body })
      if (successCodes.includes(response?.code) && files?.length) {
        const formData = new FormData()
        files.forEach(photo => formData.append('photo', photo))
        formData.append('type_document', content_type)
        formData.append('id_document', response.data.id)
        const fileResponse = await dispatch('setFileSailor', formData)
        commit('SET_DATA_STATEMENT', { isLoading: false })
        return { ...response, files: fileResponse.data.files }
      }
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async updateWorkDocumentStatementExtraDocument ({ commit, dispatch }, { id, documentId, type, body, files, content_type }) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response = await api.patch(statements.workDocumentStatementExtraDocument(id, type, documentId), { body })
      if (successCodes.includes(response?.code) && files?.length) {
        const formData = new FormData()
        files.forEach(photo => formData.append('photo', photo))
        formData.append('type_document', content_type)
        formData.append('id_document', documentId)
        const fileResponse = await dispatch('setFileSailor', formData)
        commit('SET_DATA_STATEMENT', { isLoading: false })
        return { ...response, files: fileResponse.data.files }
      }
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async removeWorkDocumentStatementExtraDocument ({ commit }, { id, documentId, type }) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response = await api.delete(statements.workDocumentStatementExtraDocument(id, type, documentId), { responseType: 'text' })
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async removeWorkDocumentStatementExtraDocumentFile ({ commit }, { id, documentId, fileId, type, body }) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response = await api.delete(statements.workDocumentStatementExtraDocumentFile(id, type, documentId, fileId),
        { body, responseType: 'text' })
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async getWorkDocumentStatementDependencies ({ commit }, id) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response = await api.get(statements.workDocumentStatementDependencies(id))
      if (successCodes.includes(response?.code)) {
        response.data.map(item => {
          item.documents = item.documents.map(value => {
            if (item.type === 'education_documents') {
              if (!value.manual_educational_institutions) {
                value.manual_educational_institutions = { name_ukr: '', name_eng: '', is_active: false }
              } else value.manual_educational_institutions.is_active = true

              if (!value.manual_educational_specialities) {
                value.manual_educational_specialities = { name_ukr: '', name_eng: '', is_active: false }
              } else value.manual_educational_specialities.is_active = true
            }
            return value
          })
          return item
        })
      }
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async checkWorkDocumentStatementDependenciesValidation ({ commit }, id) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response = await api.get(statements.workDocumentStatementDependenciesValidation(id))
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    generateWorkDocumentReport(context, params) {
      return api.get(`${statements.generateWorkDocumentReport}?${params}`)
    },
    setSignFileWorkDocumentStatementForm (context, body) {
      return api.post(statements.signWorkDocumentForm, { body })
    },
    setSignTokenWorkDocumentStatementForm (context, body) {
      return api.post(statements.signTokenWorkDocumentForm, { body })
    },
    setSignIdGovUaWorkDocumentStatementForm (context, body) {
      return api.post(statements.signIdGovUaWorkDocumentForm, { body })
    },
    downloadSignedWorkDocumentStatement(context, id) {
      return api.get(statements.workDocumentStatementArchive(id), { responseType: 'blob' })
    },
    downloadSignedWorkDocumentStatementDecision(context, id) {
      return api.get(statements.workDocumentStatementDecisionArchive(id), { responseType: 'blob' })
    },

    // WORK DOCUMENTS - ASSIGNED USERS
    async getAssignedUserInQualificationStatement({ commit }) {
      const response = await api.get(statements.assignedUsers)
      if (successCodes.includes(response?.code)) {
        const data = response.data.map(i => {
          i.full_name = `${i.last_name} ${i.first_name} ${i.userprofile.middle_name}`
          return i
        })
        commit('SET_DATA_STATEMENT', { assignedUsers: data })
      }
      return response
    },
    async getAllAssignedUsers ({ commit }) {
      const response = await api.get(statements.allAssignedUsers)
      if (successCodes.includes(response?.code)) {
        const data = response.data.map(i => {
          i.full_name = `${i.last_name} ${i.first_name} ${i.userprofile.middle_name}`
          return i
        })
        commit('SET_DATA_STATEMENT', { allAssignedUsers: data })
      }
      return response
    },

    // APPEAL STATEMENTS (WORK DOCUMENT / EXAMINATION)
    async getAppealStatements({ commit, dispatch }, { type, params }) {
      dispatch('updateRequestController')
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response =  await api.get(
        statements.appeal(type) + params,
        { signal: requestController.signal })
      if (successCodes.includes(response?.code)) commit('SET_DATA_STATEMENT', { appeal: response.data })
      if (response?.data.name !== 'AbortError') commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async getAppealStatement({ commit }, { type, id }) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response =  await api.get(statements.appeal(type, id))
      if (successCodes.includes(response?.code)) {
        response.data.isFilesLoading = true
        commit('SET_DATA_STATEMENT', { appealOpen: response.data })
        // Load statement files below
        if (response.data.decision_files?.length) response.data.decision_files = await checkFiles(response.data.decision_files)
        if (response.data.decisions_history?.length > 1) {
          response.data.decisions_history.map(async item => {
            if (item.decision_files?.length) item.decision_files = await checkFiles(item.decision_files)
            return item
          })
        }
        response.data.isFilesLoading = false
        commit('SET_DATA_STATEMENT', { appealOpen: response.data })
      }
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async setAppealStatement({ commit }, { type, body }) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response =  await api.post(statements.appeal(type), { body })
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async updateAppealStatement({ commit, dispatch }, { type, id, body, files, file_type }) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response =  await api.patch(statements.appeal(type, id), { body })
      if (successCodes.includes(response?.code) && files?.length) {
        await dispatch('uploadFilesAppealStatement', { type, id, files, file_type })
        await dispatch('getAppealStatement', { type, id })
      }
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async uploadFilesAppealStatement(context, { type, id, files, file_type }) {
      const body = new FormData()
      files.forEach(file => body.append('files', file))
      if (file_type) body.append('file_type', file_type)
      return api.post(statements.appealUploadFiles(type, id), { body })
    },
    async removeAppealStatement({ commit }, { type, id }) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response = await api.delete(statements.appeal(type, id), { responseType: 'text' })
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async getAppealStatementAssignedUsers ({ commit }, type) {
      const response = await api.get(statements.appealAssignedUsers(type))
      if (successCodes.includes(response?.code)) {
        response.data.map(i => {
          i.full_name = `${i.last_name} ${i.first_name} ${i.userprofile.middle_name || ''}`
          return i
        })
        commit('SET_DATA_STATEMENT', { assignedUsers: response.data })
      }
      return response
    },
    setSignFileAppealStatementDecision (context, body) {
      return api.post(statements.signAppealStatement('work_document_appeal'), { body })
    },
    setSignTokenAppealStatementDecision (context, body) {
      return api.post(statements.signTokenAppealStatement('work_document_appeal'), { body })
    },
    setSignIdGovUaAppealStatementDecision (context, body) {
      return api.post(statements.signIdGovUaAppealStatement('work_document_appeal'), { body })
    },
    setSignFileExaminationAppealStatementDecision (context, body) {
      return api.post(statements.signAppealStatement('exam_appeal'), { body })
    },
    setSignTokenExaminationAppealStatementDecision (context, body) {
      return api.post(statements.signTokenAppealStatement('exam_appeal'), { body })
    },
    setSignIdGovUaExaminationAppealStatementDecision (context, body) {
      return api.post(statements.signIdGovUaAppealStatement('exam_appeal'), { body })
    },
    async generateAppealStatementDecision({ commit }, { id, type, body }) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response = await api.post(statements.generateAppealDecision(type, id), { body })
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    generateAppealStatementBlank(context, { type, id }) {
      return api.get(statements.generateAppealBlank(type, id))
    },
    setSignFileAppealStatementBlank (context, body) {
      return api.post(statements.signAppealBlank('work_document_appeal'), { body })
    },
    setSignTokenAppealStatementBlank (context, body) {
      return api.post(statements.signTokenAppealBlank('work_document_appeal'), { body })
    },
    setSignIdGovUaAppealStatementBlank (context, body) {
      return api.post(statements.signIdGovUaAppealBlank('work_document_appeal'), { body })
    },
    setSignFileExaminationAppealStatementBlank (context, body) {
      return api.post(statements.signAppealBlank('exam_appeal'), { body })
    },
    setSignTokenExaminationAppealStatementBlank (context, body) {
      return api.post(statements.signTokenAppealBlank('exam_appeal'), { body })
    },
    setSignIdGovUaExaminationAppealStatementBlank (context, body) {
      return api.post(statements.signIdGovUaAppealBlank('exam_appeal'), { body })
    },
    downloadSignedAppealStatement(context, { type, id }) {
      return api.get(statements.appealStatementArchive(type, id), { responseType: 'blob' })
    },
    downloadSignedAppealStatementDecision(context, { type, id }) {
      return api.get(statements.appealStatementDecisionArchive(type, id), { responseType: 'blob' })
    },

    // PROFESSIONAL COMPETENCE
    async getProfessionalCompetenceStatements ({ commit, dispatch }, params) {
      dispatch('updateRequestController')
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response =  await api.get(
        statements.examination() + params,
        { signal: requestController.signal })
      if (successCodes.includes(response?.code)) commit('SET_DATA_STATEMENT', { professionalCompetence: response.data })
      if (response?.data.name !== 'AbortError') commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async getProfessionalCompetenceStatement ({ commit }, id) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response =  await api.get(statements.examination(id))
      if (successCodes.includes(response?.code)) {
        response.data.isFilesLoading = true
        commit('SET_DATA_STATEMENT', { professionalCompetenceOpen: response.data })
        // Load statement files below
        if (response.data.certificate_file) response.data.certificate_files = await checkFiles([response.data.certificate_file])
        await Promise.all(response.data.identity_documents?.map(async item => {
          if (item.files?.length) item.files = await checkFiles(item.files)
          return item
        }))
        response.data.isFilesLoading = false
        commit('SET_DATA_STATEMENT', { professionalCompetenceOpen: response.data })
      }
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async setProfessionalCompetenceStatement ({ commit }, body) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response =  await api.post(statements.examination(), { body })
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async updateProfessionalCompetenceStatement ({ commit }, { id, body }) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response =  await api.patch(statements.examination(id), { body })
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async removeProfessionalCompetenceStatement ({ commit }, id) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response =  await api.delete(statements.examination(id))
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    setSignFileProfessionalCompetenceStatement ({ state }, body) {
      body.append('status_document', state.professionalCompetenceExamResults.status_document)
      body.append('uploaded_file', state.professionalCompetenceExamResults.files[0])
      return api.post(statements.signExamination, { body })
    },
    setSignTokenFileProfessionalCompetenceStatement ({ state }, body) {
      body.append('status_document', state.professionalCompetenceExamResults.status_document)
      body.append('uploaded_file', state.professionalCompetenceExamResults.files[0])
      return api.post(statements.signTokenExamination, { body })
    },
    setSignIdGovUaFileProfessionalCompetenceStatement ({ state }, body) {
      body.append('status_document', state.professionalCompetenceExamResults.status_document)
      body.append('uploaded_file', state.professionalCompetenceExamResults.files[0])
      return api.post(statements.signIdGovUaExamination, { body })
    },
    async generateProfessionalCompetenceStatementBlank({ commit }, { id, body }) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response = await api.post(statements.generateExaminationBlank(id), { body })
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    setSignFileProfessionalCompetenceStatementBlank (context, body) {
      return api.post(statements.signExaminationBlank, { body })
    },
    setSignTokenProfessionalCompetenceStatementBlank (context, body) {
      return api.post(statements.signTokenExaminationBlank, { body })
    },
    setSignIdGovUaProfessionalCompetenceStatementBlank (context, body) {
      return api.post(statements.signIdGovUaExaminationBlank, { body })
    },
    downloadProfessionalCompetenceStatementBlankArchive (context, id) {
      return api.get(statements.examinationBlankArchive(id), { responseType: 'blob' })
    },
    downloadProfessionalCompetenceStatementExamResultArchive (context, id) {
      return api.get(statements.examinationExamResultArchive(id), { responseType: 'blob' })
    },
    // PROFESSIONAL COMPETENCE - IDENTITY DOCUMENTS
    async setProfessionalCompetenceStatementIdentityDocument ({ commit, dispatch }, { id, files }) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response =  await api.post(statements.examinationIdentityDocuments(id))
      if (successCodes.includes(response?.code) && files?.length) {
        await dispatch('uploadFilesProfessionalCompetenceStatementIdentityDocument', { id, documentId: response.data.id, files })
      }
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    uploadFilesProfessionalCompetenceStatementIdentityDocument (context, { id, documentId, files }) {
      const body = new FormData()
      files.forEach(file => body.append('files', file))
      return api.post(statements.examinationIdentityDocumentsUploadFiles(id, documentId), { body })
    },
    async removeFileProfessionalCompetenceStatementIdentityDocument ({ commit }, { id, documentId, fileId }) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response = await api.delete(statements.examinationIdentityDocumentsRemoveFile(id, documentId, fileId), { responseType: 'text' })
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },

    // INFORMATION CORRECTION
    async getInformationCorrectionStatements ({ commit, dispatch }, params) {
      dispatch('updateRequestController')
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response =  await api.get(
        statements.informationCorrection() + params,
        { signal: requestController.signal })
      if (successCodes.includes(response?.code)) commit('SET_DATA_STATEMENT', { informationCorrection: response.data })
      if (response?.data.name !== 'AbortError') commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async getInformationCorrectionStatement ({ commit }, id) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response =  await api.get(statements.informationCorrection(id))
      if (successCodes.includes(response?.code)) {
        response.data.isFilesLoading = true
        commit('SET_DATA_STATEMENT', { informationCorrectionOpen: response.data })
        // Load statement files below
        await Promise.all(response.data.identity_documents?.map(async item => {
          if (item.files?.length) item.files = await checkFiles(item.files)
          return item
        }))
        if (response.data.reject_file) response.data.reject_file = await checkFiles(response.data.reject_file)
        response.data.isFilesLoading = false
        commit('SET_DATA_STATEMENT', { informationCorrectionOpen: response.data })
      }
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async setInformationCorrectionStatement ({ commit }, body) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response =  await api.post(statements.informationCorrection(), { body })
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async updateInformationCorrectionStatement ({ commit }, { id, body }) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response =  await api.patch(statements.informationCorrection(id), { body })
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async removeInformationCorrectionStatement ({ commit }, id) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response =  await api.delete(statements.informationCorrection(id))
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async getInformationCorrectionStatementAvailableDocuments ({ commit }, uuid) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response =  await api.get(statements.informationCorrectionAvailableDocuments(uuid))
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    generateInformationCorrectionStatementBlank(context, id) {
      return api.get(statements.generateInformationCorrectionBlank(id))
    },
    setSignFileInformationCorrectionStatementBlank (context, body) {
      return api.post(statements.signInformationCorrectionBlank, { body })
    },
    setSignTokenInformationCorrectionStatementBlank (context, body) {
      return api.post(statements.signTokenInformationCorrectionBlank, { body })
    },
    setSignIdGovUaInformationCorrectionStatementBlank (context, body) {
      return api.post(statements.signIdGovUaInformationCorrectionBlank, { body })
    },
    downloadInformationCorrectionStatementBlankArchive (context, id) {
      return api.get(statements.informationCorrectionBlankArchive(id), { responseType: 'blob' })
    },

    // INFORMATION CORRECTION - IDENTITY DOCUMENTS
    async setInformationCorrectionStatementIdentityDocument ({ commit, dispatch }, { id, body, files }) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response =  await api.post(statements.informationCorrectionIdentityDocuments(id), { body })
      if (successCodes.includes(response?.code) && files?.length) {
        await dispatch('uploadFilesInformationCorrectionIdentityDocument', { id, documentId: response.data.id, files })
      }
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async updateInformationCorrectionStatementIdentityDocument ({ commit, dispatch }, { id, documentId, body, files }) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response =  await api.patch(statements.informationCorrectionIdentityDocuments(id, documentId), { body })
      if (successCodes.includes(response?.code) && files?.length) {
        await dispatch('uploadFilesInformationCorrectionIdentityDocument', { id, documentId, files })
      }
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },
    async uploadFilesInformationCorrectionIdentityDocument (context, { id, documentId, files }) {
      const body = new FormData()
      files.forEach(file => body.append('files', file))
      await api.post(statements.informationCorrectionIdentityDocumentsUploadFiles(id, documentId), { body })
    },
    async removeFileInformationCorrectionIdentityDocument ({ commit }, { id, documentId, fileId }) {
      commit('SET_DATA_STATEMENT', { isLoading: true })
      const response = await api.delete(statements.informationCorrectionIdentityDocumentsRemoveFile(id, documentId, fileId), { responseType: 'text' })
      commit('SET_DATA_STATEMENT', { isLoading: false })
      return response
    },

    setFileSailor(context, body) {
      return api.post(client.uploadFile, { body })
    }
  }
}
