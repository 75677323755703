<template lang="pug">
v-app.arms-right
  TopBar
  NavigationMenu(v-if="!$route.meta.public")
  v-main
    router-view(v-if="$route.meta.public")
    v-container(v-else fluid).pa-8
      router-view(:key="$route.path")
  LoaderModule(v-show="!isLoadApp")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import userActivityMixin from '@/mixins/userActivity'
import { Accessibility } from 'accessibility'
import AccessibilityConfiguration from '@/configs/accessibility'
import { checkUrlAndToken } from '@/configs'

export default {
  name: 'App',
  mixins: [userActivityMixin],
  components: {
    NavigationMenu: () => import('@/components/NavigationMenu.vue'),
    TopBar: () => import('@/components/TopBar.vue'),
    LoaderModule: () => import('@/components/modules/Loader.vue')
  },
  computed: {
    ...mapState({
      isLoadApp: state => state.app.isLoad,
      isLoadedDirectories: state => state.directory.isLoadedDirectories
    })
  },
  watch: {
    '$route' () {
      if (!this.isLoadedDirectories && checkUrlAndToken()) {
        this.getDirectories()
        this.setupActivityCheck()
      }
    }
  },
  mounted () {
    new Accessibility(AccessibilityConfiguration)
  },
  methods: {
    ...mapActions(['getDirectories'])
  }
}
</script>
