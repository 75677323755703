import i18n from '@/plugins/vue-i18n'
import Vue from 'vue'

export const base64ToBlob = encoded => {
  const type = base64MimeType(encoded)
  if (!type) return undefined
  let byteString = atob(encoded.split(',')[1])
  let ab = new ArrayBuffer(byteString.length)
  let ia = new Uint8Array(ab)

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type })
}

export const base64MimeType = encoded => {
  let result = null
  if (typeof encoded !== 'string') return result
  const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
  if (mime?.length) result = mime[1]
  return result
}

export const blobToBase64 = blob => {
  if (!blob) return
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export const queryString = (object) => {
  if (object && Object.keys(object).length) {
    let params = new URLSearchParams()
      Object.entries(object).forEach(([key, value])=> params.set(key, value))
    return `?${params}`
  } else return ''
}

export const clearBody = (object) => {
  let cloneObject = {...object}
  Object.entries(cloneObject).forEach(([key, value]) => {
    if ((Array.isArray(value) && !value.length) || (typeof value === 'object' && value !== null && !Object.keys(value).length) ||
      [undefined, null, ''].includes(value)) delete cloneObject[key]
  })
  return cloneObject
}

export const clearTokenValues = () => {
  Object.entries(localStorage).map(key => {
    if (key[0].includes('TOKEN')) localStorage.removeItem(key[0])
  })
  document.cookie.split(';').forEach(cookie => {
    const [name] = cookie.trim().split('=')
    if (name === 'TOKEN') document.cookie = 'TOKEN=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;'
  })
}

export const getDateFormat = date => {
  if (date) return date.split('T')[0].split('-').reverse().join('.')
  else return ''
}

export const convertDateTime = (date) => { //Convert time and date to current timezone
  if (!date) return ''
  const currentDate = new Date(date)
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return currentDate.toLocaleString('uk', { timeZone, hour12: false })
}

export const fileConverting = file => {  // Convert file to URLObject to get image preview
  let URL = window.URL || window.webkitURL
  if (file) return URL.createObjectURL(file)
}

export const convertObjectToFormData = obj => { // Convert nested object to FormData
  const formData = new FormData()
  const appendFormData = (data, root = '') => {
    if (data instanceof File) {
      formData.append(root, data)
    } else if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        appendFormData(data[i], root + '[' + i + ']')
      }
    } else if (typeof data === 'object' && data) {
      for (let key in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(key)) appendFormData(data[key], root === '' ? key : root + key)
      }
    } else if (data !== null && typeof data !== 'undefined') formData.append(root, data)
  }
  appendFormData(obj)
  return formData
}

export const downloadFile = (encoded, fileName = null) => { // Download blob or base64 file
  if (!encoded) return

  const link = document.createElement('a')
  document.body.appendChild(link)

  // Expose the href of link and change the extension for signed files from .p7s to .pdf.p7s to be able to view them
  if (typeof encoded === 'string') {
    link.href = encoded
    if (base64MimeType(encoded) === 'application/pkcs7-signature') fileName = `${fileName || +new Date()}.pdf.p7s`
  }
  else {
    link.href = fileConverting(encoded)
    if (encoded.type === 'application/pkcs7-signature') fileName = `${fileName || +new Date()}.pdf.p7s`
  }

  if (fileName) link.download = fileName

  link.addEventListener('click', () => {
    setTimeout(() => URL.revokeObjectURL(link.href), 100)
    document.body.removeChild(link)
  }, { once: true })

  link.click()
}

// Calculate the number of working days between two dates
export const differenceBetweenDates = (firstDate, secondDate) => {
  const startDate = new Date(firstDate)
  const endDate = new Date(secondDate)
  const ifThen = function (a, b, c) {
    return a === b ? c : a
  }

  let elapsed = endDate - startDate
  elapsed /= 86400000

  let daysBeforeFirstSaturday = (7 - startDate.getDay()) % 7
  let daysAfterLastSunday = endDate.getDay()

  elapsed -= (daysBeforeFirstSaturday + daysAfterLastSunday)
  elapsed = (elapsed / 7) * 5
  elapsed += ifThen(daysBeforeFirstSaturday - 1, -1, 0) + ifThen(daysAfterLastSunday, 6, 5)

  return Math.ceil(elapsed)
}

export const isValidHttpUrl = string => {
  if (!string || typeof string !== 'string') return false
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }
  return ['http:', 'https:'].includes(url.protocol)
}

export const convertStringToNumber = value => {
  return !isNaN(value) && value !== '0' ? Number(value) : value
}

export const actionConfirmation = async (configurations = {}) => {
  const {
    title = 'payAttention',
    text,
    confirmButtonText = 'btn.continue',
    cancelButtonText = 'btn.reject',
    showCancelButton = true
  } = configurations
  const result = await Vue.prototype.$swal({
    title: i18n.t(title),
    text: i18n.t(text),
    confirmButtonText: i18n.t(confirmButtonText),
    showCancelButton,
    cancelButtonText: i18n.t(cancelButtonText),
    showCloseButton: true,
    reverseButtons: true
  })
  return result.isConfirmed
}

export const groupArrayByObjectKey = (arr, key) => {
  return arr.reduce((acc, obj) => {
    const keyValue = obj[key]
    if (!acc[keyValue]) acc[keyValue] = []
    acc[keyValue].push(obj)
    return acc
  }, {})
}

export function checkUrlAndToken() {
  const url = window.location.href
  const hasTokenInCookies = document.cookie.split(';').some(cookie => cookie.trim().startsWith('TOKEN='))
  const hasTokenInLocalStorage = !!localStorage.getItem('TOKEN')

  return (url.startsWith('https://') && hasTokenInCookies) || (url.startsWith('http://') && hasTokenInLocalStorage)
}

export const bytesToSize = (bytes, decimals = 2) => {
  if (!bytes || bytes === 0) return '0B'

  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(1024))

  return parseFloat((bytes / Math.pow(1024, i)).toFixed(dm)) + sizes[i]
}

