import api, { checkFiles } from '@/plugins/api'
import { documents as endpoints } from '@/configs/endpoints'
import { successCodes } from '@/configs/constants'

let requestController = null

export default {
  state: () => ({
    qualificationDocuments: {},
    qualificationDocumentOpen: {},
    etiCertificates: {},
    etiCertificateOpen: {},
    etiCertificateSubmittingStatus: {},
    etiCertificateSubmittingStatusOpen: {},
    isLoading: false
  }),
  mutations: {
    SET_STATE_DATA_DOCUMENT (state, data) {
      Object.entries(data).forEach(([key, value]) => (state[key] = value))
    }
  },
  actions: {
    updateRequestController () {
      // Used to stop execution of a previous request
      if (requestController) requestController.abort()
      requestController = new AbortController()
    },

    // QUALIFICATION DOCUMENTS
    async getQualificationDocuments ({ commit, dispatch }, params) {
      dispatch('updateRequestController')
      commit('SET_STATE_DATA_DOCUMENT', { isLoading: true })
      const response =  await api.get(
        endpoints.workDocument() + params,
        { signal: requestController.signal })
      if (successCodes.includes(response?.code)) commit('SET_STATE_DATA_DOCUMENT', { qualificationDocuments: response.data })
      if (response?.data.name !== 'AbortError') commit('SET_STATE_DATA_DOCUMENT', { isLoading: false })
      return response
    },
    async getQualificationDocument ({ commit, dispatch }, id) {
      commit('SET_STATE_DATA_DOCUMENT', { isLoading: true })
      const response =  await api.get(endpoints.workDocument(id))
      if (successCodes.includes(response?.code)) {
        if (response.data.status_document === 19) {
          // Get the strict blank changes history of valid document
          const historyResponse = await dispatch('getQualificationDocumentStrictBlankHistory', id)
          if (successCodes.includes(historyResponse?.code)) response.data.strictBlankHistory = historyResponse.data
        }

        if (response.data.files?.length) response.data.files = await checkFiles(response.data.files)
        commit('SET_STATE_DATA_DOCUMENT', { qualificationDocumentOpen: response.data })
      }
      commit('SET_STATE_DATA_DOCUMENT', { isLoading: false })
      return response
    },
    async updateQualificationDocument ({ commit }, { id, body }) {
      commit('SET_STATE_DATA_DOCUMENT', { isLoading: true })
      const response =  await api.patch(endpoints.workDocument(id), { body })
      commit('SET_STATE_DATA_DOCUMENT', { isLoading: false })
      return response
    },
    downloadQualificationArchive (context, id) {
      return api.get(endpoints.workDocumentArchive(id), { responseType: 'blob' })
    },
    getQualificationDocumentStrictBlankHistory (context, id) {
      return api.get(endpoints.workDocumentStrictBlankHistory(id))
    },

    // CERTIFICATES ETI
    async getCertificatesETI ({ commit, dispatch }, params) {
      dispatch('updateRequestController')
      commit('SET_STATE_DATA_DOCUMENT', { isLoading: true })
      const response =  await api.get(
        endpoints.etiCertificate() + params,
        { signal: requestController.signal })
      if (successCodes.includes(response?.code)) commit('SET_STATE_DATA_DOCUMENT', { etiCertificates: response.data })
      if (response?.data.name !== 'AbortError') commit('SET_STATE_DATA_DOCUMENT', { isLoading: false })
      return response
    },
    async getCertificateETI ({ commit }, id) {
      commit('SET_STATE_DATA_DOCUMENT', { isLoading: true })
      const response =  await api.get(endpoints.etiCertificate(id))
      if (successCodes.includes(response?.code)) {
        response.data.isFilesLoading = true
        commit('SET_STATE_DATA_DOCUMENT', { etiCertificateOpen: response.data })
        // Load statement files below
        if (response.data.files?.length) response.data.files = await checkFiles(response.data.files)
        if (response.data.identity_documents) {
          await Promise.all(response.data.identity_documents.map(async item => {
            if (item.files?.length) item.files = await checkFiles(item.files)
            return item
          }))
        }
        response.data.isFilesLoading = false
        commit('SET_STATE_DATA_DOCUMENT', { etiCertificateOpen: response.data })
      }
      commit('SET_STATE_DATA_DOCUMENT', { isLoading: false })
      return response
    },
    async setCertificateETI ({ commit, dispatch }, { body, files }) {
      commit('SET_STATE_DATA_DOCUMENT', { isLoading: true })
      const response =  await api.post(endpoints.etiCertificate(), { body })
      if (successCodes.includes(response?.code) && files?.length) await dispatch('uploadFilesCertificatesETI', { id: response.data.id, files })
      commit('SET_STATE_DATA_DOCUMENT', { isLoading: false })
      return response
    },
    async updateCertificateETI ({ commit, dispatch }, { id, body, files }) {
      commit('SET_STATE_DATA_DOCUMENT', { isLoading: true })
      const response =  await api.patch(endpoints.etiCertificate(id), { body })
      if (successCodes.includes(response?.code) && files?.length) await dispatch('uploadFilesCertificatesETI', { id, files })
      commit('SET_STATE_DATA_DOCUMENT', { isLoading: false })
      return response
    },
    async removeCertificateETI ({ commit }, id) {
      commit('SET_STATE_DATA_DOCUMENT', { isLoading: true })
      const response =  await api.delete(endpoints.etiCertificate(id), { responseType: 'text' })
      commit('SET_STATE_DATA_DOCUMENT', { isLoading: false })
      return response
    },
    uploadFilesCertificatesETI(context, { id, files }) {
      const body = new FormData()
      files.forEach(file => body.append('files', file))
      return api.post(endpoints.etiCertificateUploadFiles(id), { body })
    },
    async removeFileCertificatesETI({ commit }, { id, fileId }) {
      commit('SET_STATE_DATA_DOCUMENT', { isLoading: true })
      const response =  await api.delete(endpoints.etiCertificateRemoveFile(id, fileId), { responseType: 'text' })
      commit('SET_STATE_DATA_DOCUMENT', { isLoading: false })
      return response
    },
    async getAvailableCoursesCertificateETI ({ commit }, id) {
      commit('SET_STATE_DATA_DOCUMENT', { isLoading: true })
      const response =  await api.get(endpoints.etiCertificateAvailableCourses(id))
      commit('SET_STATE_DATA_DOCUMENT', { isLoading: false })
      return response
    },
    downloadCertificateEtiArchive (context, id) {
      return api.get(endpoints.etiCertificateArchive(id), { responseType: 'blob' })
    },
    uploadCertificatesEti (context, body) {
      return api.post(endpoints.etiCertificateUploader(), { body })
    },


    // SUBMITTING DOCUMENTS STATUS
    async getSubmittingDocumentsStatuses ({ commit, dispatch }, params) {
      dispatch('updateRequestController')
      commit('SET_STATE_DATA_DOCUMENT', { isLoading: true })
      const response =  await api.get(
        endpoints.etiCertificateUploader() + params,
        { signal: requestController.signal })
      if (successCodes.includes(response?.code)) commit('SET_STATE_DATA_DOCUMENT', { etiCertificateSubmittingStatus: response.data })
      if (response?.data.name !== 'AbortError') commit('SET_STATE_DATA_DOCUMENT', { isLoading: false })
      return response
    },
    async getSubmittingDocumentsStatus ({ commit }, id) {
      commit('SET_STATE_DATA_DOCUMENT', { isLoading: true })
      const response =  await api.get(endpoints.etiCertificateUploader(id))
      if (successCodes.includes(response?.code)) {
        commit('SET_STATE_DATA_DOCUMENT', { etiCertificateSubmittingStatusOpen: response.data })
      }
      commit('SET_STATE_DATA_DOCUMENT', { isLoading: false })
      return response
    }
  }
}
