const apiVersion = 'api/v1'

export const directories = [
  { endpoint: `${apiVersion}/directories/sex/`, state: 'sex' },
  { endpoint: `${apiVersion}/directories/status_document/`, state: 'statusDocument' },
  { endpoint: `${apiVersion}/directories/country/`, state: 'country' },
  { endpoint: `${apiVersion}/directories/eti/`, state: 'eti' },
  { endpoint: `${apiVersion}/directories/diia/ranks/`, state: 'diiaRanks' },
  { endpoint: `${apiVersion}/directories/diia/branch_offices/`, state: 'diiaBranchOffices' },
  { endpoint: `${apiVersion}/directories/diia/educational_levels/`, state: 'diiaEducationLevels' },
  { endpoint: `${apiVersion}/directories/diia/educational_institutions/`, state: 'diiaEducationInstitutions' },
  { endpoint: `${apiVersion}/directories/diia/reasons/`, state: 'diiaReasons' },
  { endpoint: `${apiVersion}/directories/diia/positions/`, state: 'diiaPositions' },
  { endpoint: `${apiVersion}/directories/diia/type_certificates/`, state: 'diiaTypeCertificates' },
  { endpoint: `${apiVersion}/directories/diia/functions/`, state: 'diiaFunctions' },
  { endpoint: `${apiVersion}/directories/diia/levels/`, state: 'diiaLevels' },
  { endpoint: `${apiVersion}/directories/diia/limitations/`, state: 'diiaLimitations' },
  { endpoint: `${apiVersion}/directories/diia/functions_and_levels_for_position/`, state: 'diiaPositionFunctionsAndLevels' },
  { endpoint: `${apiVersion}/directories/diia/limitations_for_function/`, state: 'diiaFunctionLimitations' },
  { endpoint: `${apiVersion}/directories/diia/limitations_for_position/`, state: 'diiaPositionLimitations' },
  { endpoint: `${apiVersion}/directories/diia/vessel_type/`, state: 'diiaVesselTypes' },
  { endpoint: `${apiVersion}/directories/diia/educational_specialities/`, state: 'diiaEducationalSpecialities' },
  { endpoint: `${apiVersion}/directories/diia/educational_type_documents/`, state: 'diiaEducationalTypeDocuments' },
  { endpoint: `${apiVersion}/directories/diia/eti_courses/`, state: 'diiaEtiCourses' },
  { endpoint: `${apiVersion}/directories/diia/sti/`, state: 'diiaSti' },
  // { endpoint: `${apiVersion}/directories/diia/medical_institutions/`, state: 'diiaMedicalInstitutions' }
]

export const singleDirectories = {
  diiaDirectories: id => `${apiVersion}/directories/diia/${id ? `${id}/` : ''}`,
  diiaBranchOffices: id => `${apiVersion}/directories/diia/branch_offices/${id ? `${id}/` : ''}`,
  diiaRanks: id => `${apiVersion}/directories/diia/ranks/${id ? `${id}/` : ''}`,
  diiaEducationalInstitutions: id => `${apiVersion}/directories/diia/educational_institutions/${id ? `${id}/` : ''}`,
  diiaReasons: id => `${apiVersion}/directories/diia/reasons/${id ? `${id}/` : ''}`,
  diiaEducationalSpecialities: id => `${apiVersion}/directories/diia/educational_specialities/${id ? `${id}/` : ''}`,
  diiaPositions: id => `${apiVersion}/directories/diia/positions/${id ? `${id}/` : ''}`,
  diiaTypeCertificates: id => `${apiVersion}/directories/diia/type_certificates/${id ? `${id}/` : ''}`,
  diiaFunctionLimitations: id => `${apiVersion}/directories/diia/limitations_for_function/${id ? `${id}/` : ''}`,
  diiaLimitations: id => `${apiVersion}/directories/diia/limitations/${id ? `${id}/` : ''}`,
  diiaPositionLimitations: id => `${apiVersion}/directories/diia/limitations_for_position/${id ? `${id}/` : ''}`,
  diiaStatementDocuments: id => `${apiVersion}/directories/diia/type_document_for_statement/${id ? `${id}/` : ''}`,
  diiaEducationalTypeDocuments: id => `${apiVersion}/directories/diia/educational_type_documents/${id ? `${id}/` : ''}`,
  diiaEtiCourses: id => `${apiVersion}/directories/diia/eti_courses/${id ? `${id}/` : ''}`,
  diiaSti: id => `${apiVersion}/directories/diia/sti/${id ? `${id}/` : ''}`,
  diiaMedicalInstitutions: id => `${apiVersion}/directories/diia/medical_institutions/${id ? `${id}/` : ''}`
}

export const users = {
  profile: `${apiVersion}/accounts/me/`,
  user: id => `${apiVersion}/accounts/users/${id ? `${id}/` : ''}`,
  audit: (type, id) => `${apiVersion}/audit/${type}/${id ? `${id}/` : ''}`
}

export const auth = {
  general: {
    login: `${apiVersion}/accounts/login/`,
  },
  eds: {
    getKey: `${apiVersion}/accounts/digital_sign/auth_key/`,
    login: `${apiVersion}/accounts/digital_sign/login/`
  },
  testLogin: `${apiVersion}/accounts/test_login/`,
  logout: `${apiVersion}/accounts/logout/`,
  idgovua: `${apiVersion}/accounts/login/idgovua/`
}

export const client = {
  info: id => `${apiVersion}/requests/client/${id ? `${id}/` : ''}`,
  uploadFile: `${apiVersion}/clients/photo_uploader/`
}

export const statements = {
  // Work document statement
  workDocument: id => `${apiVersion}/requests/diia/work_document/${id ? `${id}/` : ''}`,
  workDocumentUploadFiles: id => `${apiVersion}/requests/diia/work_document/${id}/upload_files/`,
  generateDecisionWorkDocument: id => `${apiVersion}/requests/diia/work_document/${id}/generate_decision_on_statement/`,
  generateWorkDocumentStatement: id => `${apiVersion}/requests/diia/work_document/${id}/generate_statement/`,
  combinedFileWorkDocumentStatement: id => `${apiVersion}/requests/diia/work_document/${id}/get_documents_in_one_file/`,
  signDecisionWorkDocument: `${apiVersion}/signature/diia_statement_decision/`,
  signTokenDecisionWorkDocument: `${apiVersion}/signature/diia_statement_decision/token/`,
  signIdGovUaDecisionWorkDocument: `${apiVersion}/signature/diia_statement_decision/idgovua/`,
  signWorkDocumentForm: `${apiVersion}/signature/diia_statement_work_document_form/`,
  signTokenWorkDocumentForm: `${apiVersion}/signature/diia_statement_work_document_form/token/`,
  signIdGovUaWorkDocumentForm: `${apiVersion}/signature/diia_statement_work_document_form/idgovua/`,
  workDocumentComment: id => `${apiVersion}/requests/diia/work_document/${id}/comment/`,
  generateWorkDocument: id => `${apiVersion}/diia_documents/work_documents/${id}/generate_document/`,
  signWorkDocument: `${apiVersion}/signature/diia_work_document/`,
  signTokenWorkDocument: `${apiVersion}/signature/diia_work_document/token/`,
  signIdGovUaWorkDocument: `${apiVersion}/signature/diia_work_document/idgovua/`,
  assignLimitationsWorkDocument: id => `${apiVersion}/requests/diia/work_document/${id}/assign_limitations/`,
  workDocumentStatementExtraDocument: (id, type, documentId) => {
    return `${apiVersion}/requests/diia/work_document/${id}/${type}/${documentId ? `${documentId}/` : ''}`
  },
  workDocumentStatementExtraDocumentFile: (id, type, documentId, fileId) => {
    return `${apiVersion}/requests/diia/work_document/${id}/${type}/${documentId}/delete_file/${fileId}/`
  },
  workDocumentStatementDependencies: id => `${apiVersion}/requests/diia/work_document/${id}/document_dependencies/`,
  workDocumentStatementDependenciesValidation: id => `${apiVersion}/requests/diia/work_document/${id}/check_documents_validation/`,
  generateWorkDocumentReport: `${apiVersion}/requests/diia/work_document/generate_report/`,
  assignedUsers: `${apiVersion}/requests/diia/work_document/available_assignation_users/`,
  allAssignedUsers: `${apiVersion}/requests/diia/work_document/all_assignation_users/`,
  workDocumentStatementArchive: id => `${apiVersion}/requests/diia/work_document/${id}/download_archive/`,
  workDocumentStatementDecisionArchive: id => `${apiVersion}/requests/diia/work_document/${id}/download_result_archive/`,
  // Appeal statements
  appeal: (type, id) => `${apiVersion}/requests/diia/${type}/${id ? `${id}/` : ''}`,
  appealUploadFiles: (type, id) => `${apiVersion}/requests/diia/${type}/${id}/upload_files/`,
  appealAssignedUsers: type => `${apiVersion}/requests/diia/${type}/available_commission_users/`,
  signAppealStatement: type => `${apiVersion}/signature/${type}/`,
  signTokenAppealStatement: type => `${apiVersion}/signature/${type}/token/`,
  signIdGovUaAppealStatement: type => `${apiVersion}/signature/${type}/idgovua/`,
  generateAppealDecision: (type, id) => `${apiVersion}/requests/diia/${type}/${id}/generate_decision_on_statement/`,
  generateAppealBlank: (type, id) => `${apiVersion}/requests/diia/${type}/${id}/generate_application_form/`,
  signAppealBlank: type => `${apiVersion}/signature/diia_statement_${type}_application_form/`,
  signTokenAppealBlank: type => `${apiVersion}/signature/diia_statement_${type}_application_form/token/`,
  signIdGovUaAppealBlank: type => `${apiVersion}/signature/diia_statement_${type}_application_form/idgovua/`,
  appealStatementArchive: (type, id) => `${apiVersion}/requests/diia/${type}/${id}/download_archive/`,
  appealStatementDecisionArchive: (type, id) => `${apiVersion}/requests/diia/${type}/${id}/download_result_archive/`,
  // Examination/Professional competence statement
  examination: id => `${apiVersion}/requests/diia/exam/${id ? `${id}/` : ''}`,
  signExamination: `${apiVersion}/signature/diia_statement_exam/`,
  signTokenExamination: `${apiVersion}/signature/diia_statement_exam/token/`,
  signIdGovUaExamination: `${apiVersion}/signature/diia_statement_exam/idgovua/`,
  examinationIdentityDocuments: (id, documentId) => {
    return `${apiVersion}/requests/diia/exam/${id}/identity_documents/${documentId ? `${documentId}/` : ''}`
  },
  examinationIdentityDocumentsUploadFiles: (id, documentId) => {
    return `${apiVersion}/requests/diia/exam/${id}/identity_documents/${documentId}/upload_files/`
  },
  examinationIdentityDocumentsRemoveFile: (id, documentId, fileId) => {
    return `${apiVersion}/requests/diia/exam/${id}/identity_documents/${documentId}/delete_file/${fileId}/`
  },
  generateExaminationBlank: id => `${apiVersion}/requests/diia/exam/${id}/generate_application_form/`,
  signExaminationBlank: `${apiVersion}/signature/diia_statement_exam_application_form/`,
  signTokenExaminationBlank: `${apiVersion}/signature/diia_statement_exam_application_form/token/`,
  signIdGovUaExaminationBlank: `${apiVersion}/signature/diia_statement_exam_application_form/idgovua/`,
  examinationBlankArchive: id => `${apiVersion}/requests/diia/exam/${id}/download_archive/`,
  examinationExamResultArchive: id => `${apiVersion}/requests/diia/exam/${id}/download_result_archive/`,
  // Information correction statement
  informationCorrection: id => `${apiVersion}/requests/diia/change_request/${id ? `${id}/` : ''}`,
  informationCorrectionIdentityDocuments: (id, documentId) => {
    return `${apiVersion}/requests/diia/change_request/${id}/identity_documents/${documentId ? `${documentId}/` : ''}`
  },
  informationCorrectionIdentityDocumentsUploadFiles: (id, documentId) => {
    return `${apiVersion}/requests/diia/change_request/${id}/identity_documents/${documentId}/upload_files/`
  },
  informationCorrectionIdentityDocumentsRemoveFile: (id, documentId, fileId) => {
    return `${apiVersion}/requests/diia/change_request/${id}/identity_documents/${documentId}/delete_file/${fileId}/`
  },
  informationCorrectionAvailableDocuments: uuid => `${apiVersion}/requests/diia/change_request/available_documents/${uuid}/`,
  generateInformationCorrectionBlank: id => `${apiVersion}/requests/diia/change_request/${id}/generate_application_form/`,
  signInformationCorrectionBlank: `${apiVersion}/signature/diia_change_request_application_form/`,
  signTokenInformationCorrectionBlank: `${apiVersion}/signature/diia_change_request_application_form/token/`,
  signIdGovUaInformationCorrectionBlank: `${apiVersion}/signature/diia_change_request_application_form/idgovua/`,
  informationCorrectionBlankArchive: id => `${apiVersion}/requests/diia/change_request/${id}/download_archive/`
}

export const documents = {
  // Work documents
  workDocument: id => `${apiVersion}/diia_documents/work_documents/${id ? `${id}/` : ''}`,
  workDocumentArchive: id => `${apiVersion}/diia_documents/work_documents/${id}/download_archive/`,
  workDocumentStrictBlankHistory: id => `${apiVersion}/diia_documents/work_documents/${id}/get_strict_blank_history/`,
  // Certificates ETI
  etiCertificate: id => `${apiVersion}/diia_documents/certificate_eti/${id ? `${id}/` : ''}`,
  etiCertificateUploadFiles: id => `${apiVersion}/diia_documents/certificate_eti/${id}/upload_files/`,
  etiCertificateRemoveFile: (id, fileId) => `${apiVersion}/diia_documents/certificate_eti/${id}/delete_file/${fileId}/`,
  etiCertificateArchive: id => `${apiVersion}/diia_documents/certificate_eti/${id}/download_archive/`,
  etiCertificateAvailableCourses: id => `${apiVersion}/diia_documents/certificate_eti/${id ? `${id}/` : ''}available_courses/`,
  etiCertificateUploader: id => `${apiVersion}/diia_documents/certificate_eti_parse_xlsx/${id ? `${id}/` : ''}`
}

export const media = {
  media: `${apiVersion}/media/`
}
