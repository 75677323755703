export default {
  menu: {
    client: 'Моряк',
    qualificationDocuments: 'Кваліфікаційні документи',
    qualificationDocument: 'Кваліфікаційний документ',
    document: 'Документ',
    experienceDocuments: 'Стаж',
    medicalDocuments: 'Медичні документи',
    admin: 'Адмін панель',
    users: 'Користувачі',
    user: 'Користувач',
    userRegistration: 'Створити новий обліковий запис',
    statements: 'Заяви',
    statementsSailor: 'Заяви моряка',
    workDocumentStatements: 'Заяви на отримання кваліфікаційного документа',
    workDocumentStatement: 'Заява на отримання кваліфікаційного документа',
    workDocumentStatementCreate: 'Створення заяви на отримання кваліфікаційного документа',
    workDocumentStatementEditing: 'Редагування заяви на отримання кваліфікаційного документа',
    qualificationDocumentReceiving: 'Отримання кваліфікаційного документа',
    directories: 'Довідники',
    creteDirectories: 'Додати новий довідник',
    information: 'Інформація',
    decisionAppealShort: 'Оскарження рішень',
    decisionAppeal: 'Оскарження рішень Адміністрації судноплавства',
    decisionAppealStatement: 'Заява на оскарження рішення Адміністрації судноплавства',
    decisionAppealStatementCreate: 'Створення заяв на оскарження рішення',
    documentsSailor: 'Документи моряка',
    audit: 'Аудит',
    professionalCompetenceCheck: 'Перевірка професійної компетентності',
    professionalCompetenceCheckStatements: 'Заяви на проведення перевірки професійної компетентності',
    professionalCompetenceCheckStatement: 'Заява на проведення перевірки професійної компетентності',
    professionalCompetenceCheckStatementCreate: 'Створення заяви на проведення перевірки професійної компетентності',
    professionalCompetenceCheckStatementEdit: 'Редагування заяви на проведення перевірки професійної компетентності',
    informationCorrection: 'Виправлення відомостей у ДРДМ',
    informationCorrectionStatements: 'Заяви на виправлення відомостей у ДРДМ',
    informationCorrectionStatement: 'Заява на виправлення відомостей у ДРДМ',
    informationCorrectionStatementCreate: 'Створення заяви на виправлення відомостей у ДРДМ',
    informationCorrectionStatementEdit: 'Редагування заяви на виправлення відомостей у ДРДМ',
    reports: 'Звіти',
    etiCertificates: 'Свідоцтва ЗПМ',
    etiCertificate: 'Свідоцтво ЗПМ',
    etiCertificateAdd: 'Додати свідоцтво ЗПМ',
    etiCertificateEdit: 'Редагувати свідоцтво ЗПМ',
    submittingDocumentsStatus: 'Статус внесення документів'
  },
  btn: {
    create: 'Створити',
    back: 'Назад',
    save: 'Зберегти',
    edit: 'Редагувати',
    clear: 'Очистити',
    read: 'Зчитати',
    sign: 'Підписати',
    confirm: 'Підтвердити',
    upload: 'Завантажити',
    destroy: 'Видалити',
    createReport: 'Сформувати звіт',
    goHome: 'На головну',
    reject: 'Скасувати',
    generate: 'Згенерувати',
    continue: 'Продовжити',
    send: 'Відправити'
  },
  notify: {
    title: {
      info: 'Зверніть увагу!',
      success: 'Успіх',
      warning: 'Увага',
      error: 'Помилка'
    },
    success: {
      copy: 'Скопійовано успішно',
      active: "Користувача успішно активовано",
      deactivate: "Користувача успішно деактивовано"
    },
    fileAlreadyUploaded: 'Файл {fileName} уже було додано',
    maxFilesCount: 'Додано максимально дозволену кількість файлів - {count}',
    exceedMaximumFileSize: 'Перевищено максимально дозволений розмір файлу',
    invalidFileType: 'Невірне розширення файлу',
    'Not found ': 'Інформації не знайдено',
    'You can not deactivate yourself ': 'Ви не можете деактивувати власний профіль',
    'Geoposition required': "Будь ласка, увімкніть вашу геолокацію, перш ніж продовжити",
    'Location error ': 'Доступ до сервісу обмежено у вашому регіоні',
    'Template for document does not exists': 'Шаблону для документа не існує',
    'date_end - cannot update record': 'Не можна відредагувати',
    'date_start - cannot update record': 'Дата початку не може бути раніше чим завтра',
    'Duplicate has been already created': 'Дублікат уже створено',
    'Status document is not valid': 'Документ про статус недійсний',
    'Document is signed with cifra, or sailor`s name didnt change': 'Документ підписаний цифрою, або прізвище моряка не змінилося',
    'Couldnt create duplicate': 'Не вдалося створити дублікат',
    'Sailor does not have email': 'У моряка не має веріфікованого email',
    'Valid sign is not found for the user': 'У користувача немає дійсного водяного знака підпису',
    'Profile does not exists': 'Профіль не існує',
    'Sailor has no full name in English': 'У моряка немає повного імені англійською',
    'NotAllowedError - The operation either timed out or was not allowed': 'Операція або минула, або не була дозволена',
    'Port Sevastopol has no captain!': 'У порту Севастополь немає капітана',
    'Image size must be 400x400 pixels!': 'Розмір зображення має бути 400x400 пікселів',
    'The submitted data was not a file  Check the encoding type on the form': 'Надані дані не були файлом. Перевірте тип кодування на формі',
    'SecurityError - This is an invalid domain': 'Помилка безпеки – це недійсний домен',
    'SecurityError - The relying party ID is not a registrable domain suffix of, nor equal to the current domain': 'Помилка безпеки – Ідентифікатор перевіряючої сторони не є суфіксом домену, який можна зареєструвати, і не дорівнює поточному домену',
    'No file was submitted': 'Жоден файл не відправлено',
    'Image size must be 400x400 pixels ': 'Розмір зображення має бути 400x400 пікселів',
    'Select a valid choice  That choice is not one of the available choices ': 'Виберіть правильне значення. Цей значення не є одним із доступних',
    'Sailor does not exists': 'Моряка не існує',
    'Incorrect username or password': "Неправильне ім'я користувача або пароль",
    'Sailor not found': 'Моряка не знайдено',
    'User is blocked': 'Користувача заблоковано',
    'User does not found': 'Користувача не знайдено',
    'Please contact a administrator to add u2f': 'Зверніться до адміністратора, щоб додати u2f',
    'Info for this task not found': 'Інформація для цього завдання не знайдена',
    'Photo for document not found': 'Фото для документа не знайдено',
    'Document must be in Wait signature status': 'Документ має бути в статусі «Очікування підписання',
    'Select a valid choice That choice is not one of the available choices': 'Виберіть правильний вибір. Цей вибір не є одним із доступних.',
    'Please send body with data': 'Тіло запиту не має бути пустим',
    'Status is wrong': 'Статус неправильний',
    'Mismatch of user type': 'Невідповідність типу користувача',
    'Status document is incorrect for education': 'Cтатус документа не відповідає освіті',
    'Invalid page': 'Недійсна сторінка',
    'Bad answer from service': 'Погана відповідь від сервіса',
    'You do not have permission to perform this action': 'Ви не маєте дозволу на виконання цієї дії',
    'User has no relation to the port': 'Користувач не має відношення до порту',
    'Related docs does not exists': 'Пов’язаних документів не існує',
    'Invalid token': 'Не валідний токін повторіть авторизацію',
    'sailor photo is not valid': 'У моряка немає валідного фото',
    'last_name and first_name is required': "Прізвище та Ім'я обов'язкові для пошуку моряка",
    'You do not have permission to perform this action ': 'У вас немає дозволу на виконання цієї дії',
    'Authentication credentials were not provided ': 'Облікові дані для автентифікації не були надані',
    'Cant create service record with delivery to morrichservice': 'Неможливо створити ПКМ з типом доставки моррічсервіс',
    'A user with that username already exists ': 'Користувач з такою поштою вже існує',
    'User profile with this tax number already exists ': 'Користувач з таким РНОКПП вже існує',
    'User profile with this unique demographic number already exists ': 'Користувач з таким УНЗР вже існує',
    'Please use your signature': 'Будь ласка, використовуйте свій підпис',
    'Profile not found': 'Профіль не знайдено',
    'diia reason with this sequence number already exists ': 'Причина відхилення з таким номером вже існує',
    'Document with this number already exists': 'Документ із таким номером уже існує',
    'Statement is not in draft': 'Заява вже знаходиться в обробці',
    'Failed to create request for selected options ': 'Створення заяви неможливе',
    'Library need extension': 'Виникла помилка при взаємодії з криптографічною бібліотекою. Веб-розширення для роботи бібліотеки web-підпису не запущено або не інстальовано в браузері. Для продовження необхідно запустити або інсталювати веб-розширення',
    'Library not installed': 'Виникла помилка при взаємодії з криптографічною бібліотекою. Додаток для роботи бібліотеки підпису не запущено або не інстальовано . Для продовження необхідно запустити або інсталювати додаток',
    'Instance is used': 'Видалення неможливе. Це значення вже використовується',
    'User with this type not found': 'Користувачів з таким типом облікового запису не знайдено або були заблоковані',
    'User not found': 'Користувача не знайдено',
    'Invalid signature': 'Недійсний підпис',
    'Unable to add document  Validity of confirmation is more than 6 months': 'Неможливо додати документ. Термін дії підтвердження більше ніж 6 місяців.',
    'Too many statements, limit to 600': 'Перевищено запит на формування звіту, скористайтесь фільтром. Максимально 600 записів',
    'secretary_sqc not exists': 'Не знайдено активних членів ДККМ',
    'No active members secretary_sqc': 'Не знайдено активних членів ДККМ',
    'Can not decode code param': 'Не вдається розшифрувати параметр коду',
    'Unable to login via id gov ua': 'Не вдається увійти через id.gov.ua',
    'Unable to get user data via id gov ua': 'Не вдається отримати дані користувача через id.gov.ua',
    'Unable to get user tax number via id gov ua': 'Не вдається отримати податковий номер користувача через id.gov.ua',
    'Can not open file': 'Не вдається відкрити файл',
    'Invalid worksheet headers': 'Неправильні заголовки файлу',
    'Invalid signature type': 'Використовуйте фізичний підпис',
    'File download error': 'Помилка завантаження файлу',
    'Invalid status document': 'Недійсний статус документа',
    'Signed document not be fount': 'Підписаний документ не знайдено',
    saveUser: {
      success: 'Користувач створений',
      error: 'Помилка створення користувача'
    },
    editUser: {
      success: 'Дані користувача змінено',
      error: 'Помилка змінення даних користувача'
    },
    assignedUser: {
      success: 'Відповідальну особу змінено',
      error: 'Помилка змінення відповідальної особи'
    },
    serverError: 'Помилка сервера. Будь ласка, зверніться до адміністрації сайту для вирішення проблеми.',
    statementChanged: {
      success: 'Заява змінена',
      error: 'Заява не змінена'
    },
    signDocument: {
      success: 'Підписано успішно',
      error: 'При підписані виникла помилка',
    },
    validation: {
      required: 'Поле обов`язкове для заповнення',
      number_doc: 'Номер може містити цифри від 0 до 9, та символи .-/ ',
      value: 'Не вірне значення',
      length: 'Довжина поля не коректна',
      maxValue: 'Перевищено максимальне значення',
      minValue: 'Перевищено мінімальне значення',
      maxLength: 'Перевищено максимальну довжину',
      minLength: 'Перевищено мінімальну довжину',
      alphaUA: 'Використовуйте тільки українську',
      alphaEN: 'Використовуйте тільки латинські літери',
      email: 'Введіть корректний email',
      minStringLength: 'Мінімальна довжина: {min} символів',
      fileNameFormat: 'Використовуйте лише доступні символи',
      isWeekday: 'Неможливо обрати вихідний день'
    },
    downloadedDocument: 'Документ завантажено на пристрій',
    uploadedDocument: 'Документ був завантажен',
    createdDirectory: 'Довідник був оновлений',
    changedDirectory: 'Довідник був змінений',
    removedDirectory: 'Довідник був видалений',
    finishedWorkDocumentStatementProcess: 'Процес створення заяви отримання кваліфікаційного документа закінчено',
    changesDecisionAppealStatement: 'Заява на оскарження рішення була оновлена',
    createdDecisionAppealStatement: 'Заява на оскарження рішення була створена',
    deletedDecisionAppealStatement: 'Заява на оскарження рішення видалено',
    changedReviewInformation: 'Інформацію щодо розгляду заяви було змінено',
    useSignature: 'Використовуйте ключ файлового підпису',
    noData: 'Немає даних',
    createdProfessionalCompetenceStatement: 'Заява на проведення перевірки професійної компетентності була створена',
    deletedProfessionalCompetenceStatement: 'Заява на проведення перевірки професійної компетентності була видалена',
    updateProfessionalCompetenceStatement: 'Заява на проведення перевірки професійної компетентності була оновлена',
    createdInformationCorrectionStatement: 'Заява на виправлення відомостей у ДРДМ була створена',
    deletedInformationCorrectionStatement: 'Заява на виправлення відомостей у ДРДМ була видалена',
    updatedInformationCorrectionStatement: 'Заява на виправлення відомостей у ДРДМ була оновлена',
    noInformationByUniqueDemographicNumber: 'В системі немає данних по вказаному УНЗР',
    noInformationByTaxNumber: 'В системі немає данних по вказаному УНЗР',
    createdCertificateETI: 'Свідоцтво ЗПМ було створено',
    updatedCertificateETI: 'Свідоцтво ЗПМ було оновлено',
    deletedCertificateETI: 'Свідоцтво ЗПМ було видалено',
    deletedWorkDocumentStatement: 'Заяву на отримання кваліфікаційного документа видалено',
    updatedQualificationDocument: 'Кваліфікаційний документ було оновлено',
  },
  noData: 'Дані відсутні',
  sex: 'Стать',
  rank: 'Звання',
  position: 'Посада',
  phoneNumber: 'Номер телефону',
  qualificationDocuments: 'Кваліфікаційні документи',
  educationDocuments: 'Освітні документи',
  number: 'Номер',
  typeDocument: 'Тип документа',
  status: 'Статус',
  status_document: 'Статус документа',
  educationInstitution: 'Навчальний заклад',
  action: 'Дія',
  comment: 'Коментар',
  dateStart: 'Дата видачі',
  dateEnd: 'Дата закінчення',
  courseDateEnd: 'Дата закінчення курсу',
  strictBlank: 'Бланк суворої звітності',
  fullName: 'ПІБ',
  fullNameUkr: 'ПІБ українською',
  nameCompany: 'Організація',
  email: 'Email',
  typeUser: 'Тип користувача',
  password: 'Пароль',
  lastName: 'Прізвище',
  firstName: 'Ім`я',
  lastNameEng: 'Прізвище англійською',
  firstNameEng: 'Ім`я англійською',
  fullNameEng: 'Ім\'я та прізвище англійською',
  signInAccount: 'Введіть свій email',
  limitation: 'Обмеження',
  hospital: 'Лікарня',
  doctor: 'Лікар',
  country: 'Країна',
  numberShip: 'Номер судна',
  nameShip: 'Назва судна',
  typeShip: 'Тип судна',
  modeOfNavigation: 'Режим плавання',
  portShipRegistration: 'Порт судна',
  shipOwner: 'Власник судна',
  grossCapacity: 'Валова місткість судна',
  propulsionPower: 'Потужність ГЕУ (кВт)',
  refrigeratingPower: 'Холодопродуктивність (кКал/год)',
  electricalPower: 'Потужність суднового електрообладнання (кВт)',
  typeGeu: 'Тип ГЕУ',
  levelRefrigerPlant: 'Кількість холодопродуктивних установок',
  tradingArea: 'Райони плавання',
  captainNameUkr: 'ПІБ капітана українською',
  captainFirstName: 'Ім\'я капітана',
  captainLastName: 'Прізвище капітана',
  captainNameEng: 'ПІБ капітана англійською',
  placeStart: 'Місце влаштування на судно',
  placeEnd: 'Місце звільнення із судна',
  datePlacement: 'Дата влаштування на судно',
  releaseDate: 'Дата звільнення із судна',
  units: {
    kWat: 'кВт',
    calHour: 'кКал/год',
    year: 'рік',
  },
  qualification: 'Кваліфікація',
  speciality: 'Спеціальність',
  specialityNameUkr: 'Спеціальність українською',
  specialityNameEng: 'Спеціальність англійською',
  dateIssue: 'Дата видачі',
  expiredDate: 'Дата припинення дії',
  yearEnd: 'Рік закінчення',
  diploma: 'Диплом',
  created: 'Дата створення',
  start: 'Дата початку дії',
  type: 'Тип',
  branchOffice: 'Філія',
  middleName: 'По батькові',
  eti: 'НТЗ',
  course: 'Курс',
  documentSigning: 'Підписання документа',
  key: 'Ключ',
  edrpou: 'ЄДРПОУ',
  drfo: 'ДРФО',
  typeKey: 'Тип ключа',
  issuedBy: ' Ким видано',
  qualificationDocument: 'Кваліфікаційний документ',
  yes: 'Так',
  no: 'Ні',
  reason: 'Причина',
  copyDoc: 'Копії документа',
  decision: 'Рішення',
  sailor: 'ПІБ моряка',
  issue: 'Ким видано',
  dateForFilter: {
    date_birth_full_title: 'Дата народження (дата/період)',
    date_birth: 'Дата народження',
    from_date_birth: 'Дата народження з',
    to_date_birth: 'Дата народження по',
    date_start_full_title: 'Дата видачі (дата/період)',
    date_start: 'Дата видачі',
    from_date_start: 'Дата видачі з',
    to_date_start: 'Дата видачі по',
    date_meeting: 'Дата події',
    from_date_meeting: 'З дати події',
    to_date_meeting: 'По дату події',
    from_date: 'Дата створення з',
    to_date: 'Дата створення по',
    date_create_full_title: 'Дата створення (дата/період)',
    date_create: 'Дата створення',
    date_issue: 'Дата видачі',
    from_date_issue: 'Дата видачі з',
    to_date_issue: 'Дата видачі по',
    review_deadline: 'Крайній термін розгляду заяви',
    review_deadline_full_title: 'Крайній термін розгляду заяви (дата/період)',
    from_review_deadline: 'Крайній термін розгляду заяви з',
    to_review_deadline: 'Крайній термін розгляду заяви по',
    exam_date_full_title: 'Дата проведення іспиту (дата/період)',
    exam_date: 'Дата проведення іспиту',
    from_exam_date: 'Дата проведення іспиту з',
    to_exam_date: 'Дата проведення іспиту по',
    date_end_full_title: 'Дата припинення дії (дата/період)',
    date_end: 'Дата припинення дії',
    from_date_end: 'Дата припинення дії з',
    to_date_end: 'Дата припинення дії по'
  },
  dateBirth: 'Дата народження',
  loadingText: 'Завантаження...',
  noResultsText: 'Дані не знайдено',
  changeStatus: 'Змінити статус',
  tooltip: {
    info: 'Детальна інформація',
    active: "Активувати",
    deactivate: "Деактивувати"
  },
  taxNumber: 'РНОКПП',
  purpose: 'Призначення платежа',
  typeStatement: 'Тип заяви',
  exit: 'Вийти',
  authorization: 'Авторизація',
  signIn: 'Авторизуватись',
  mainData: 'Основні дані',
  setOtherKey: 'Обрати інший ключ',
  tryAgain: 'Не вдалося виконати дію, спробуйте ще раз',
  recordsFound: 'Записів знайдено',
  rejectReason: 'Причина відхилення',
  rejectedBy: 'Ким відхилено',
  rejectDate: 'Дата відхилення',
  commissionHead: 'Голова комісії',
  commissionDeputyHead: 'Заступник Голови комісії',
  commissionSecretary: 'Секретар комісії',
  commissionMember: 'Член комісії',
  oops: 'Упс! Трапилась несподіванка!',
  pageNotExist: 'Вибачте, такої сторінки не існує, або була допущена помилка при вводі адреси сайту.',
  sailorCabinetRegistration: 'Реєстрація в кабінеті моряка',
  citizenship: 'Громадянство',
  index: 'Індекс',
  name: 'Назва',
  documents: 'Документи',
  statements: 'Заяви',
  fileKey: 'Файловий носій',
  tokenKeyKey: 'Захищений носій',
  user: 'Користувач',
  uniqueRegistryNumber: 'УНЗР',
  search: 'Пошук',
  medicalInstitution: 'Медичний заклад',
  level: 'Рівень',
  authWithRole: 'Авторизуватися під роллю',
  makeDecision: 'Винести рішення',
  changeDecision: 'Змінити рішення',
  makeWorkDocumentDecision: 'Винести рішення щодо видачі кваліфікаційного документа',
  documentCopy: 'Скан-копія документа',
  role: 'Роль',
  userEditing: 'Редагування облікового запису',
  sendToPrint: 'Відправити на друк',
  generatedDocument: 'Згенерований документ',
  nameUkr: 'Назва українською',
  nameEng: 'Назва англійською',
  directoryDetails: 'Детальна інформація за довідником',
  id: 'ID',
  description: 'Опис',
  rulesClause: 'Пункт правил',
  generateStatementBlank: 'Згенерувати бланк заяви',
  generateDecisionBlank: 'Згенерувати бланк рішення',
  visuallyImpairedVersion: 'Версія для людей з вадами зору',
  educationDocumentsData: 'Дані документа(-ів) про освіту, виданого починаючи з 2000 року',
  numberPage: 'Номер сторінки',
  blankSerial: 'Серія бланка',
  blankNumber: 'Номер бланка',
  receivedDocuments: 'Отримані документи',
  maritimeEducation: 'Морська освіта',
  diplomaPrintAndIssue: 'Роздрукувати та видати Диплом',
  endorsementPrintAndIssue: 'Роздрукувати та видати Підтвердження',
  userStatus: 'Статус користувача',
  chooseLimitation: 'Виберіть обмеження',
  positionFunctionLimitations: 'Обмеження за функціями',
  limitationFunction: 'Функція обмежень',
  positionLimitations: 'Обмеження за посадою',
  limitations: 'Обмеження',
  typeDirectory: 'Тип довідника',
  limitationNameUkr: 'Обмеження українською',
  limitationNameEng: 'Обмеження англійською',
  levelNameUkr: 'Рівень українською',
  levelNameEng: 'Рівень англійською',
  pleaseFillForm: 'Будь ласка, заповніть форму',
  fullTitle: 'Повна назва',
  shortTitle: 'Коротка назва',
  serialAndNumber: 'Серія та номер',
  step: 'Крок {step}',
  applicantInformation: 'Інформація про заявника',
  statementInformation: 'Інформація про заяву',
  identityDocument: 'Посвідчення особи моряка або інший документ, що посвідчує особу та підтверджує громадянство',
  sqcProtocolFullTitle: 'Сертифікат про успішне складання комп\'ютерного тесту CES 6.0',
  isRequired: 'Є обовʼязковим',
  familiarizeDocumentContent: 'Ознайомтесь зі змістом документа, якщо все вірно - перед підписанням його необхідно роздрукувати!',
  familiarizeDocumentConfirmation: 'Своєю дією ви підтверджуєте, що документ був вже роздрукованій та готовий до видачі!',
  payAttention: 'Зверніть увагу!',
  statementDocuments: 'Документи, що додаються до заяви',
  considerationDaysLeft: 'Залишилось днів на розгляд',
  addNewRecord: 'Додати новий запис',
  addNewExperienceRecord: 'Додати новий запис про стаж',
  addNewExperienceDocument: 'Додати новий документ про стаж',
  addNewLimitation: 'Додати нове обмеження',
  statementReviewDeadline: 'Крайній термін розгляду заяви',
  appealExplanation: 'Пояснення щодо оскарження',
  statement: 'Заява',
  makeAppealStatementDecision: 'Винести рішення щодо видачі оскарження',
  returnedDueToAppeal: 'Повернули через апеляцію',
  address: 'Адреса',
  appointment: 'Призначення',
  institutionNameUkr: 'Навчальний заклад українською',
  institutionNameEng: 'Навчальний заклад англійською',
  vesselPortRegistration: 'Порт приписки судна',
  vesselOwner: 'Судновласник',
  vesselOfficialNumber: 'Офіційний номер судна',
  vesselPosition: 'Посада на судні',
  portsOfCall: 'Порти заходження',
  fillingData: 'Дата заповнення',
  rankAndQualification: 'Звання / Тип свідоцтва',
  createdByMorrichservice: 'Створено ДП "Моррічсервіс"',
  skipStep: 'Пропустити крок',
  unfinishedProcessConformation: 'Процес створення заяви буде збережений в чернетці, але не був повністю завершений. Ви впевнені, що хочете залишити форму створення заяви?',
  leavePageConfirmation: 'Ви впевнені, що хочете залишити форму створення заяви?',
  leavePage: 'Залишити сторінку',
  continueCreating: 'Продовжити створення',
  assignedUser: 'Відповідальна особа',
  changeAssignedUser: 'Заміна відповідальної особи',
  existStatus: 'Статус наявності',
  welcome: 'Вітаємо!',
  forStartWorkChoosePointSideMenu: 'Для початку роботи - оберіть пункт з бокового меню',
  decisionNumber: 'Номер рішення',
  decisionDate: 'Дата винесення рішення',
  viewDecision: 'Переглянути рішення',
  downloadArchiveWithDocumentAndSign: 'Завантажити архів з документа та підписів',
  presenceType: 'Тип присутності',
  assignedReviewDate: 'Призначена дата розгляду',
  exceedReviewDate: 'Призначена дата перевищує гранічний термін, що зазначений в п. 117 Поставнови 1499 від 30 грудня 2022 р.',
  meetingAddress: 'Адреса проведення засідання',
  communicationMeans: 'Засіб зв\'язку',
  meetingPostponedReason: 'Причина перенесення засідання',
  changeMeetingInformation: 'Змінити інформацію щодо розгляду заяви',
  postponedMeetingDateHistory: 'Історія перенесення дати розгляду',
  appealCommissionMembers: 'Члени апеляційної комісії',
  operation: 'Операція',
  chooseKeyAndEnterPass: 'Оберіть ключ та введіть пароль',
  whoMakeChanges: 'Ким внесено зміни',
  changesMade: 'Внесені зміни',
  changesDescription: 'Значення "{value}" було змінено з "{pre}" на "{post}"',
  audit: {
    id: 'ID',
    number: 'Номер',
    number_document: 'Номер',
    tax_number: 'РНОКПП',
    unique_demographic_number: 'УНЗР',
    username: 'E-mail',
    last_name: 'Прізвище',
    last_name_eng: 'Прізвище (англійською)',
    first_name: 'Імʼя',
    first_name_eng: 'Імʼя (англійською)',
    middle_name: 'По батькові',
    type_user: 'Роль',
    type_statement: 'Тип заяви',
    status: 'Статус',
    status_document: 'Статус',
    is_disable: 'Статус',
    is_active: 'Статус активності',
    name_ukr: 'Назва українською',
    name_eng: 'Назва англійською',
    description: 'Опис',
    description_ukr: 'Опис',
    purpose: 'Призначення',
    appeal_explanation: 'Пояснення щодо оскарження',
    sequence_number: 'Пункт правил',
    review_deadline: 'Крайній термін розгляду заяви',
    review_at: 'Призначена дата розгляду',
    review_place: 'Адреса проведення засідання',
    diia_branch_office: 'Філія',
    diia_rank: 'Звання',
    diia_list_position: 'Посада',
    diia_decision: 'Рішення',
    diia_type_certificate: 'Кваліфікація',
    diia_type_document: 'Тип документа',
    presence_type: 'Тип присутності',
    postponement_reason: 'Причина перенесення засідання',
    communication_tool: 'Засіб зв\'язку',
    date_start: 'Дата початку дії',
    date_end: 'Дата припинення дії',
    exam_date: 'Дата проведення іспиту',
    course_date_end: 'Дата закінчення курсу',
    letter_strict_blank: 'Серія бланку суворої звітності',
    number_strict_blank: 'Номер бланку суворої звітності',
    inspection_purpose: 'Мета проведення перевірки',
    diia_eti_course: 'Курс',
    eti_institution: 'Навчальний заклад',
    changes: 'Які зміни треба внести',
    reason: 'Причина',
    reject_reason: 'Причина відхилення',
    name: 'Назва',
    head_name: 'Голова комісії з проведення медичного огляду моряків',
    member_names: 'Заступник(-и) голови з проведення медичного огляду моряків',
    institution_place: 'Адреса',
    courses: 'Курс'
  },
  changesMadeHistory: 'Історія внесених змін',
  installLibrary: 'Встановити пакет бібліотеки підпису',
  waitForSign: 'Очікує підписання',
  signed: 'Підписано',
  statementNumber: 'Номер заяви',
  decisionChangingReason: 'Причина зміни рішення',
  postponedDecisionHistory: 'Історія змінення рішень',
  educationalLevel: 'Освітній рівень',
  experienceRecords: 'Записи про стаж',
  additionalRecordInformation: 'Додаткова інформація запису',
  checkFullNameDeclaration: 'Будь ласка, уважно перевірте правильність відмінювання ПІБ. Якщо є помилка, Ви можете її виправити вручну змінивши текст в відповідному полі.',
  inGenitive: 'В родовому відмінку (кого?)',
  inDative: 'В давальному відмінку (кому?)',
  editClientInformation: 'Редагувати інформацію про заявника',
  examinationDate: 'Дата проведення іспиту',
  inspectionPurpose: 'Мета проведення перевірки',
  identityDocumentInformation: 'Інформація про документ, що посвідчує особу',
  deleteConfirmation: 'Ви впевнені, що хочете видалити заяву? Зворотний процес буде неможливим.',
  checkQes: 'Підтверждення дії з ЕЦП',
  createdBy: 'Ким створено',
  setResults: 'Виставити результати',
  endorsementByExperience: 'Підтвердження по стажу',
  changesShouldBeMade: 'Які зміни треба внести',
  noDocumentsByNumberFound: 'Документів за вказаним номером не знайдено',
  chooseCorrectionFile: 'Оберіть документ для виправлення',
  autoEndorsementLinking: 'Якщо заява створюється на виправлення професійного диплома або сертифіката компетентності, підтвердження прикріпляється автоматично за наявності',
  createAndDownloadPdf: 'Сформувати та завантажити PDF',
  signedBy: 'Ким підписано',
  availableSerialAndNumber: 'Номер та серія (серія за наявності)',
  downloadStatement: 'Завантажити заяву',
  downloadExamResult: 'Завантажити результат іспиту',
  documentName: 'Назва документа',
  checkClientInformation: 'Уважно перевірте персональні дані моряка. Якщо потрібно внести зміни, ви можете відредагувати інформацію.',
  enterReportName: 'Введіть назву звіту',
  reportCreateDescription: 'Зверніть увагу, звіт буде формуватися за заданими параметрами фільтрації та сортування за якийсь час. Після завершення формування звіт буде завантажено на ваш пристрій.',
  uploadProfessionalCompetenceCheck: 'Завантажте скан копію документа (протокол або відомість), який підтверджує проходження перевірки професійної компетенції',
  uploadDecisionCopyForSign: 'Завантажте скан копію рішення, яку буде підписано членами апеляційної комісії',
  uploadRejectFile: 'Завантажте скан копію документа про негативне рішення розгляду заяви',
  documentInformation: 'Інформація за документом',
  receivingDocumentBranchOffice: 'Філія отримання документа',
  sailorTrainingInstitution: 'Заклад підготовки моряків',
  legislation: 'Структурна одиниця Конвенції ПДНВ, Кодексу ПДНВ, чинне законодавство',
  accreditationTerm: 'Строк акредитації',
  addNewTrainingDirection: 'Додати новий напрям підготовки',
  trainingDirection: 'Напрям підготовки',
  trainingDirections: 'Напрями підготовки',
  absentUniqueDemographicNumber: 'УНЗР відсутній',
  comments: 'Коментарі',
  rejection: 'Скасування',
  notSpecified: 'Не вказано',
  privateStaff: 'Рядовий склад',
  officerStaff: 'Офіцерський склад',
  totalCommissionMembers: 'Всього членів комісії',
  presentCommissionMembers: 'Присутніх членів на засіданні',
  meetingDate: 'Дата засідання',
  meetingTimeStart: 'Час початку засідання',
  meetingTimeEnd: 'Час закінчення засідання',
  listenFor: 'Слухали',
  votedFor: 'Голосували за',
  votedAgainst: 'Голосували проти',
  agreeConclusionAddition: 'Таким чином Апеляційна комісія погоджується з висновком Державної кваліфікаційної комісії моряків...',
  firstOptionAgreeConclusionAddition: 'Враховуючи викладене, керуючись підпунктом першим пункту 109 Положення, Апеляційна комісія погоджується з висновком Державної кваліфікаційної комісії моряків...',
  useGenitiveCommissionersFullName: 'Вказуйте ПІБ учасників комісії у родовому відмінку. Ця інформація братиме участь у генерації бланка рішення апеляційної комісії.',
  downloadStatementWithSignature: 'Завантажити заяву з підписом',
  downloadDecisionWithSignature: 'Завантажити рішення з підписом',
  printedApplicationForm: 'Друкована форма бланка заяви',
  decisionRenderQuorum: 'Кворум щодо винесення рішення',
  assignDate: 'Дата призначення',
  isAssignedMember: 'Є відповідальним членом комісії',
  waitForDecision: 'Очікує рішення',
  makingDecision: 'Винесення рішення',
  meeting: 'Засідання',
  continueSentenceStatement: 'Продовжте твердження речення вказаного в назві поля',
  addNewCommissionMember: 'Додати нового члена комісії',
  typeCertificate: 'Тип свідоцтва',
  healthcareFacilityName: 'Назва закладу охорони здоров\'я',
  resolutionDateStart: 'Дата початку постанови',
  resolutionDateEnd: 'Дата закінчення постанови',
  sailorMedicalExaminationChairman: 'Голова комісії з проведення медичного огляду моряків',
  sailorMedicalExaminationChairmanDeputy: 'Заступник голови з проведення медичного огляду моряків',
  sailorMedicalExaminationChairmanDeputies: 'Заступник(-и) голови з проведення медичного огляду моряків',
  addNewDeputy: 'Додати нового заступника',
  authorizationWith: 'Авторизуватись з',
  diiaStatementNumber: 'Номер заяви з Дія',
  enterEtiCertificates: 'Внести свідоцтва ЗПМ',
  enterEtiCertificatesDescription: 'Ця опція призначена для одноразового масового запису свідоцтв ЗПМ в систему з використанням попередньо підготовленого файлу в якому міститься інформація про видані документи. Будь ласка, дочекайтесь сповіщення про результат внесення та не переходьте зі сторінки або облікового запису до завершення виконання дії.',
  downloadSignedStatement: 'Завантажити підписану заяву',
  downloadSignedDocument: 'Завантажити підписаний документ',
  acceptableFileFormats: 'Можна завантажити файли у форматі: {formats}.',
  maximumFileSize: 'Розмір файлу не повинен перевищувати {size}',
  fileName: 'Назва файлу',
  lineNumber: 'Номер рядка',
  uploadDateAndTime: 'Дата та час внесення файлу',
  uploadCompleteDateAndTime: 'Дата завершення внесення файлу',
  encounteredErrors: 'Виниклі помилки',
  allowChangeStrictBlank: 'Користувач може редагувати серію та номер бланка кваліфікаційного документа',
  changeStrictBlank: 'Змінити серію та номер бланка',
  active: 'Активний',
  blocked: 'Заблокований',
  changesHistory: 'Історія змін'
}
