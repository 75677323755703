import api from '@/plugins/api'
import { client } from '@/configs/endpoints'

export default {
  state: () => ({
    isLoading: false
  }),
  mutations: {
    SET_DATA_SAILOR (state, data) {
      Object.entries(data).forEach(([key, value]) => state[key] = value)
    }
  },
  actions: {
    async getSailor ({ commit }, checkValue) {
      commit('SET_DATA_SAILOR', { isLoading: true })
      const response = await api.get(client.info(checkValue))
      commit('SET_DATA_SAILOR', { isLoading: false })
      return response
    },
    async createSailor ({ commit }, body) {
      commit('SET_DATA_SAILOR', { isLoading: true })
      const response = await api.post(client.info(), { body })
      commit('SET_DATA_SAILOR', { isLoading: false })
      return response
    },
    async updateSailor ({ commit }, { checkValue, body }) {
      commit('SET_DATA_SAILOR', { isLoading: true })
      const response = await api.patch(client.info(checkValue), { body })
      commit('SET_DATA_SAILOR', { isLoading: false })
      return response
    }
  }
}
